import React, { useEffect, useState } from 'react'
import { useModal } from 'hooks/useModal'
import { createPortal } from 'react-dom'
import { motion, AnimatePresence, HTMLMotionProps } from 'framer-motion'

type State = {
  Component: React.ReactNode
  modalProps: HTMLMotionProps<'div'>
} | null

const ModalFactory = () => {
  const modal = useModal()

  const INITIAL_STATE = null
  const [state, setState] = useState<State>(INITIAL_STATE)

  const onStateChange = (
    Component: React.ReactNode,
    modalProps: HTMLMotionProps<'div'>
  ) => setState({ Component, modalProps })

  useEffect(() => {
    modal.listen(onStateChange)
  }, [])

  useEffect(() => {
    document.body.style.overflow = state?.Component ? 'hidden' : 'auto'
  }, [state])

  return createPortal(
    <AnimatePresence exitBeforeEnter>
      {!!state?.Component && (
        <motion.div
          key="modal"
          id="modal"
          transition={{ ease: 'easeInOut' }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          {...state?.modalProps}
          className={`fixed z-50 w-full h-full top-0 left-0 overflow-y-auto ${
            state?.modalProps.className || ''
          }`}
        >
          {state?.Component}
        </motion.div>
      )}
    </AnimatePresence>,
    document.body
  )
}

export { ModalFactory }
