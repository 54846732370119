import React from 'react'
import { ModalFactory } from 'components/ModalFactory'
import { HomeProvider } from 'contexts/Home'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { LandingPage } from './pages/LandingPage'
import { PolicyPrivacy } from './pages/PolicyPrivacy'

export const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route
            index
            element={
              <HomeProvider>
                <LandingPage />
              </HomeProvider>
            }
          />
          <Route path="politica-de-privacidade" element={<PolicyPrivacy />} />
        </Route>
      </Routes>
      <ModalFactory />
    </BrowserRouter>
  )
}
