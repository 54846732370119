/* eslint-disable react/display-name */
import React from 'react'
import { FarmapayCards } from './FarmapayCards'
import { FarmapayPayment } from '../FarmapayPayment'
import { FarmapayUncomplicate } from './FarmapayUncomplicate'
import { FaarmapayCouponCashback } from './FarmapayCouponCashback'
import { FarmapayTransfers } from './FarmapayTransfers'
import { HiArrowNarrowRight } from 'react-icons/hi'
import { useHome } from 'hooks/useHome'
import { FarmapayFinancialControl } from './FarmapayFinancialControl'
import { FarmapayDownloadNow } from './FarmapayDownloadNow'

export const ForYou = () => {
  const { setSection } = useHome()

  return (
    <section id="for-you" className="w-full flex-shrink-0">
      <FarmapayCards />
      <FarmapayPayment />
      <FarmapayUncomplicate />
      <FaarmapayCouponCashback />
      <article className="mobile:pt-14 w-full bg-tertiary mobile:pl-6 border-none">
        <div className="bg-light-2 rounded-l-full mobile:w-full desktop:w-4/5 desktop:ml-auto mobile:pr-6 desktop:pr-1/3 desktop:ml-1/3 border-none">
          <button
            className="
                bg-secondary
                rounded-full
                mobile:w-full
                desktop:w-auto
                desktop:mr-auto
                font-semibold
                mobile:text-base
                tablet:text-2xl
                mobile:p-4.5
                tablet:p-8
                text-center
                text-tertiary
                flex
                items-center
                justify-center
                relative"
            onClick={() => setSection('BUSINESS')}
          >
            <span className="mobile:hidden tablet:flex items-center text-center gap-x-2">
              Conheça as vantagens para o seu negócio{' '}
              <HiArrowNarrowRight className="mobile:w-5 mobile:h-5 tablet:w-6 tablet:h-6 text-primary" />
            </span>
            <span className="mobile:flex flex-col items-center justify-center tablet:hidden">
              Conheça as vantagens para <br />
              <span className="flex items-center gap-x-2">
                o seu negócio{' '}
                <HiArrowNarrowRight className="mobile:w-5 mobile:h-5 tablet:w-6 tablet:h-6 text-primary" />
              </span>
            </span>
          </button>
        </div>
      </article>

      <FarmapayTransfers />
      <FarmapayFinancialControl />
      <FarmapayDownloadNow />
    </section>
  )
}
