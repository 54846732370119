import axios, { AxiosInstance } from 'axios'
import { EmailService } from './Email'
import { BrasilService } from './Brasil'

class Api {
  private api: AxiosInstance
  public email: EmailService
  public brasil: BrasilService

  constructor() {
    this.api = axios.create({
      baseURL:
        'https://apihexalab-env.eba-i9ipuprd.sa-east-1.elasticbeanstalk.com/'
    })

    this.email = new EmailService(this.api)
    this.brasil = new BrasilService()
  }
}

const api = new Api()

export { api }
