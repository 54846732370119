import { AxiosInstance } from 'axios'
import { SendEmailInterface } from './types'

export class EmailService {
  constructor(protected api: AxiosInstance) {
    this.api = api
  }

  async registerCompany(data: SendEmailInterface) {
    return await this.api.post('/send-email/send-contact', data)
  }
}
