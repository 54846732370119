import { PayWhenSell } from 'assets/images/PayWhenSell'
import { StoreOnMap } from 'assets/images/StoreOnMap'
import React, { useState } from 'react'
import { HiArrowNarrowRight } from 'react-icons/hi'
import { Link } from 'react-router-dom'
import { Swiper as SwiperInterface } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

export const FarmapayBestRates = () => {
  const [activeSwiper, setActiveSwiper] = useState(0)

  const onActiveIndexChange = (swiper: SwiperInterface) =>
    setActiveSwiper(swiper.activeIndex)

  return (
    <article id="best-rates" className="w-full bg-tertiary">
      <div className="w-full tablet:container desktop:container tablet:mx-auto mobile:pt-14 mobile:px-6 tablet:px-0">
        <h3 className="font-medium mobile:text-lg tablet:text-3xl desktop:text-4xl text-quaternary">
          O Farmapay tem as{' '}
          <span className="mobile:text-primary tablet:text-secondary">
            melhores taxas
          </span>
        </h3>

        <div
          className="flex
        mobile:flex-row
        tablet:flex-col
        mobile:gap-x-3
        tablet:gap-y-4
        mobile:mt-6
        tablet:mt-10
        mobile:mb-4
        tablet:mb-10
        mobile:text-xs
        tablet:text-base
        desktop:text-lg"
        >
          <article className="bg-light flex mobile:flex-col tablet:flex-row font-medium text-center flex-1 mobile:rounded-tl-lg overflow-hidden">
            <span className="mobile:py-3 tablet:py-2 uppercase text-tertiary bg-secondary flex-1">
              Receba em
            </span>
            <span className="mobile:py-3 tablet:py-2 text-secondary flex-1">
              1 dia
            </span>
            <span className="mobile:py-3 tablet:py-2 text-secondary flex-1">
              15 dia
            </span>
            <span className="mobile:py-3 tablet:py-2 text-secondary flex-1">
              30 dia
            </span>
            <span className="mobile:py-3 tablet:py-2 text-secondary flex-1">
              45 dia
            </span>
            <span className="mobile:py-3 tablet:py-2 text-secondary flex-1">
              60 dia
            </span>
          </article>

          <article className="bg-light flex mobile:flex-col tablet:flex-row font-medium text-center flex-1 mobile:rounded-tr-lg tablet:rounded-bl-lg overflow-hidden">
            <span className="mobile:py-3 tablet:py-2 uppercase text-tertiary bg-secondary flex-1">
              Taxa
            </span>
            <span className="mobile:py-3 tablet:py-2 text-secondary flex-1">
              2,99%
            </span>
            <span className="mobile:py-3 tablet:py-2 text-secondary flex-1">
              2,51%
            </span>
            <span className="mobile:py-3 tablet:py-2 text-secondary flex-1">
              1,99%
            </span>
            <span className="mobile:py-3 tablet:py-2 text-secondary flex-1">
              1,75%
            </span>
            <span className="mobile:py-3 tablet:py-2 text-secondary flex-1">
              1,51%
            </span>
          </article>
        </div>

        <p className="text-secondary mobile:text-sm tablet:text-lg font-medium tablet:text-center desktop:text-left tablet:mx-auto desktop:mx-0">
          Ofereça pagamentos parcelados para os seus clientes com o{' '}
          <Link
            to="/"
            className="text-primary inline-flex items-center gap-x-2"
          >
            Farmapay
            <HiArrowNarrowRight className="mobile:w-4 mobile:h-4 tablet:w-5 tablet:h-5 inline text-primary" />
          </Link>{' '}
        </p>
      </div>

      <div className="w-full mobile:mt-14 tablet:pt-40">
        <div className="w-full mobile:py-6 bg-light-2 tablet:py-0 tablet:relative tablet:max-h-72">
          <div className="mobile:hidden tablet:block h-72 w-full" />
          <article
            className="mobile:hidden
          w-full
          tablet:flex
          items-start
          tablet:container
          tablet:mx-auto
          tablet:left-0
          tablet:right-0
          tablet:absolute
          tablet:-top-40"
          >
            <div className="flex flex-col items-center tablet:flex-1 tablet:pr-2">
              <PayWhenSell className="mx-auto" width={304} height={304} />
              <span
                className="font-medium
              w-full
              mobile:text-sm
              tablet:text-lg
              text-primary
              text-center
              mobile:px-6
              mobile:mt-2
              mobile:mb-1
              tablet:px-0
              tablet:mt-4
              tablet:mb-2"
              >
                Pague somente quando vender
              </span>
              <p className="font-medium w-full mobile:text-lg text-secondary text-center mobile:px-6 tablet:px-0">
                Cadastre-se já e ganhe mensalidade e <br /> adesão por R$ 0,00!
              </p>
            </div>
            <div className="flex flex-col items-center tablet:flex-1 tablet:pl-2">
              <StoreOnMap className="mx-auto" width={304} height={304} />
              <span
                className="font-medium
              w-full
              mobile:text-sm
              tablet:text-lg
              text-primary
              text-center
              mobile:px-6
              mobile:mt-2
              mobile:mb-1
              tablet:px-0
              tablet:mt-4
              tablet:mb-2"
              >
                Sua loja no mapa
              </span>
              <p className="font-medium w-full mobile:text-lg text-secondary text-center mobile:px-6 tablet:px-0">
                Mostre seu negócio para todos os <br /> usuários Farmapay
              </p>
            </div>
          </article>

          <Swiper
            slidesPerView="auto"
            onActiveIndexChange={onActiveIndexChange}
            className="tablet:hidden w-full flex items-start tablet:container tablet:mx-auto tablet:absolute tablet:-top-40"
          >
            <SwiperSlide className="flex flex-col items-center tablet:flex-1 tablet:pr-2">
              <PayWhenSell className="mx-auto" width={304} height={304} />
              <span
                className="font-medium
              w-full
              mobile:text-sm
              tablet:text-lg
              text-primary
              text-center
              mobile:px-6
              mobile:mt-2
              mobile:mb-1
              tablet:px-0
              tablet:mt-4
              tablet:mb-2"
              >
                Pague somente quando vender
              </span>
              <p className="font-medium w-full mobile:text-lg text-secondary text-center mobile:px-6 tablet:px-0">
                Cadastre-se já e ganhe mensalidade e adesão por R$ 0,00!
              </p>
            </SwiperSlide>
            <SwiperSlide className="flex flex-col items-center tablet:flex-1 tablet:pl-2">
              <StoreOnMap className="mx-auto" width={304} height={304} />
              <span
                className="font-medium
              w-full
              mobile:text-sm
              tablet:text-lg
              text-primary
              text-center
              mobile:px-6
              mobile:mt-2
              mobile:mb-1
              tablet:px-0
              tablet:mt-4
              tablet:mb-2"
              >
                Sua loja no mapa
              </span>
              <p className="font-medium w-full mobile:text-lg text-secondary text-center mobile:px-6 tablet:px-0">
                Mostre seu negócio para todos os usuários Farmapay
              </p>
            </SwiperSlide>
          </Swiper>

          <div className="w-full mobile:flex items-center justify-center gap-x-2 mt-4 tablet:hidden">
            <div
              className={`${
                activeSwiper === 0 ? 'w-4 bg-tertiary' : 'w-2 bg-quinary'
              } h-2 rounded-full transition-all duration-300`}
            ></div>
            <div
              className={`${
                activeSwiper === 1 ? 'w-4 bg-tertiary' : 'w-2 bg-quinary'
              } h-2 rounded-full transition-all duration-300`}
            ></div>
          </div>
        </div>
      </div>
    </article>
  )
}
