/* eslint-disable react/prop-types */
import React from "react";
import { Link, LinkProps } from "react-router-dom";

export const LinkButton = ({
  to,
  children,
  className,
  onClick,
  ...props
}: LinkProps) => {
  const rippleRef = React.useRef<HTMLDivElement>(null);

  const rippleClassName = "ripple";

  /* const handleOnClick: React.MouseEventHandler<HTMLAnchorElement> = (event) => {
    event.preventDefault();

    rippleRef.current!.className = `${rippleClassName} ripple__animation`;

    onClick?.(event);

    setTimeout(() => {
      rippleRef.current!.className = rippleClassName;
    }, 300);
  };
 */
  const Tag = (props: any) =>
    to.toString().includes("#") ? (
      <a href={to} {...props} />
    ) : (
      <Link to={to} {...props} />
    );

  return (
    <Tag
      className={`
    flex
    items-center
    justify-center
    bg-primary
    text-tertiary
    font-semibold
    rounded-3xl
    cursor-pointer
    overflow-hidden
    relative
    transition-all
    duration-300
    ${className}`}
      {...props}
    >
      <div ref={rippleRef} className={rippleClassName} />
      {children}
    </Tag>
  );
};
