import React, { useState } from 'react'
import { Control, Controller, RegisterOptions } from 'react-hook-form'
import { HiChevronDown, HiChevronUp } from 'react-icons/hi'

interface Option {
  label: string
  value: string
}

interface Props
  extends React.DetailedHTMLProps<
  React.LabelHTMLAttributes<HTMLLabelElement>,
  HTMLLabelElement
  > {
  name: string
  control: Control
  rules?: RegisterOptions
  defaultValue: string
  options: Option[]
}

export const Select = ({
  control,
  name,
  className,
  options,
  defaultValue,
  ...props
}: Props) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onBlur, onChange, ...field }, fieldState }) => (
        <label
          htmlFor={name}
          className={`relative flex flex-col items-start transition-all duration-300 pt-5 pb-4 overflow-y-visible ${className}`}
          {...props}
        >
          <input id={name} type="hidden" {...field} />

          <button
            type="button"
            className={`
            cursor-pointer
            bg-tertiary 
            appearance-none
            outline-none
            focus:outline-none
            w-full
            text-left
            p-3
            text-sm
            text-secondary
            rounded-lg
            border
            transition-all
            duration-300
            ${fieldState.error ? 'border-error' : 'border-light-2'}
            `}
            onClick={() => setIsOpen((previous) => !previous)}
            onBlur={() => {
              onBlur()
              setIsOpen(false)
            }}
          >
            {options.find((option) => option.value === field.value)?.label ||
              defaultValue}
          </button>

          <div
            className={`absolute top-14 w-full bg-tertiary border-light-2 rounded-b-lg flex flex-col transition-all duration-300 z-10 ${isOpen
                ? 'max-h-36 overflow-auto border-l border-b border-r mb-4'
                : 'border-0 max-h-0 overflow-hidden'
              }`}
          >
            {options.map(
              (option, index) =>
                option.value !== field.value && (
                  <label
                    key={`${name}-${option.value}-${index}`}
                    htmlFor={`${name}-${option.value}-${index}`}
                    className="appearance-none outline-none focus:outline-none w-full text-left p-3 text-sm text-quaternary cursor-pointer"
                  >
                    <input
                      id={`${name}-${option.value}-${index}`}
                      type="radio"
                      name={`${name}-${option.value}-${index}`}
                      className="appearance-none"
                      value={option.value}
                      onChange={onChange}
                    />
                    {option.label}
                  </label>
                )
            )}
          </div>

          <HiChevronDown
            className={`w-6 absolute right-3 top-8 text-primary transition-all duration-300 z-0 cursor-pointer ${isOpen ? 'opacity-0 h-3' : 'opacity-1 h-6'
              }`}
            onClick={() => setIsOpen((previous) => !previous)}
          />
          <HiChevronUp
            className={`w-6 absolute right-3 top-8 text-primary transition-all duration-300 z-0 cursor-pointer ${isOpen ? 'opacity-1 h-6' : 'opacity-0 h-3'
              }`}
            onClick={() => setIsOpen((previous) => !previous)}
          />

          <span className="text-error text-us min-h-5">
            {fieldState.error?.message}
          </span>
        </label>
      )}
    />
  )
}
