/* eslint-disable react/display-name */
import React from 'react'
import { useHome } from 'hooks/useHome'
import { FarmapayPayment } from '../FarmapayPayment'
import { FarmapayPOS } from './FarmapayPOS'
import { HiArrowNarrowLeft } from 'react-icons/hi'
import { FarmapayTapAndGo } from './FarmapayTapAndGo'
import { AllBusiness } from './AllBusiness'
import { FarmapayBestRates } from './FarmapayBestRates'
import { FarmapayRegister } from './FarmapayRegister'

export const ForBusiness = () => {
  const { setSection } = useHome()

  return (
    <section id="for-business" className="w-full flex-shrink-0">
      <FarmapayPOS />
      <FarmapayPayment />
      <FarmapayTapAndGo />

      <article className="mobile:pt-14 w-full bg-tertiary mobile:pr-6 border-none">
        <div className="bg-light-2 rounded-r-full mobile:w-full desktop:w-4/5 desktop:mr-auto mobile:pl-6 desktop:pl-1/3 desktop:mr-1/3 border-none">
          <button
            className="
                bg-secondary
                rounded-full
                mobile:w-full
                desktop:w-auto
                desktop:ml-auto
                font-semibold
                mobile:text-base
                tablet:text-2xl
                mobile:p-4.5
                tablet:p-8
                text-center
                text-tertiary
                flex
                items-center
                justify-center
                relative"
            onClick={() => setSection('YOU')}
          >
            <span className="mobile:hidden tablet:flex items-center text-center gap-x-2">
              Conheça as vantagens para você{' '}
              <HiArrowNarrowLeft className="mobile:w-5 mobile:h-5 tablet:w-8 tablet:h-8 text-primary" />
            </span>
            <span className="mobile:flex flex-col items-center justify-center tablet:hidden">
              Conheça as vantagens para <br />
              <span className="flex items-center gap-x-2">
                você{' '}
                <HiArrowNarrowLeft className="mobile:w-5 mobile:h-5 tablet:w-8 tablet:h-8 text-primary" />
              </span>
            </span>
          </button>
        </div>
      </article>

      <AllBusiness />
      <FarmapayBestRates />
      <FarmapayRegister />
    </section>
  )
}
