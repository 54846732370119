import { FarmapayPix } from 'assets/images/FarmapayPix'
import { FarmapayTransfer } from 'assets/images/FarmapayTransfer'
import React, { useState } from 'react'
import { HiArrowNarrowRight } from 'react-icons/hi'
import { Link } from 'react-router-dom'
import { Swiper as SwiperInterface } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

export const FarmapayTransfers = () => {
  const [activeSwiper, setActiveSwiper] = useState(0)

  const onActiveIndexChange = (swiper: SwiperInterface) =>
    setActiveSwiper(swiper.activeIndex)

  return (
    <div
      id="transfers"
      className="bg-tertiary w-full mobile:pt-14 mobile:pb-14 tablet:pb-10"
    >
      <Swiper
        slidesPerView="auto"
        className="w-full  second-swiper tablet:hidden"
        onActiveIndexChange={onActiveIndexChange}
      >
        <SwiperSlide className="w-full flex items-center flex-col mobile:gap-y-3 mobile:px-6">
          <FarmapayTransfer className="overflow-visible text-center" />
          <p className="mobile:text-left font-medium mobile:text-lg text-secondary">
            Com <span className="text-primary">Farmapay</span> você pode fazer
            transferências ou cobranças com foto, áudio e até anexos!
          </p>
        </SwiperSlide>
        <SwiperSlide className="w-full flex items-center flex-col mobile:gap-y-3 mobile:px-6">
          <FarmapayPix />
          <p className="mobile:text-right font-medium mobile:text-lg text-secondary">
            Ou se preferir faça Pix de um modo simples e seguro!
          </p>
        </SwiperSlide>

        <div className="w-full mobile:flex items-center justify-center gap-x-2 mt-4 tablet:hidden">
          <div
            className={`${activeSwiper === 0 ? 'w-4' : 'w-2'}
             h-2 bg-light rounded-full transition-all duration-300`}
          ></div>
          <div
            className={`${activeSwiper === 1 ? 'w-4' : 'w-2'} 
            h-2 bg-light rounded-full transition-all duration-300`}
          ></div>
        </div>
      </Swiper>
      <article className="w-full second-swiper mobile:hidden tablet:container desktop:container mx-auto tablet:flex flex-col">
        <div className="w-full flex items-center justify-start gap-x-4">
          <FarmapayTransfer className="w-80 h-80 " />
          <p className="mobile:text-left font-medium text-3xl text-secondary ">
            Com <span className="text-primary">Farmapay</span> você
            <br /> pode fazer
            <br /> transferências ou
            <br /> cobranças com
            <br /> foto, áudio e até anexos!
          </p>
        </div>
        <div className="w-full flex items-center justify-end gap-x-4">
          <p className="mobile:text-right font-medium text-3xl text-secondary flex flex-col">
            Ou se preferir faça Pix de um modo
            <br />
            simples e seguro!
            <Link
              to="/"
              className="flex items-center gap-x-2 text-lg text-primary ml-auto mt-4"
            >
              Faz um Pix <HiArrowNarrowRight className="w-5 h-5 text-primary" />
            </Link>
          </p>
          <FarmapayPix className="w-80 h-80" />
        </div>
      </article>
    </div>
  )
}
