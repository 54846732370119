import React, { SVGProps } from 'react'

export const InstagramIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} {...props}>
    <path data-name="Ret\xE2ngulo 4991" fill="none" d="M0 0h32v32H0z" />
    <g data-name="Grupo 16138">
      <path
        data-name="Uni\xE3o 14"
        d="M7.999 28.001a4.007 4.007 0 0 1-4-4v-16a4 4 0 0 1 4-4h16a4 4 0 0 1 4 4v16a4 4 0 0 1-4 4Zm-1.332-20v16a1.334 1.334 0 0 0 1.332 1.332h16a1.334 1.334 0 0 0 1.335-1.332v-16a1.334 1.334 0 0 0-1.335-1.335h-16a1.334 1.334 0 0 0-1.332 1.335Zm4 8a5.333 5.333 0 1 1 5.332 5.333 5.334 5.334 0 0 1-5.333-5.333Zm2.668 0a2.666 2.666 0 1 0 2.664-2.668 2.663 2.663 0 0 0-2.665 2.668Zm7.334-6.361a1.64 1.64 0 1 1 1.64 1.644 1.641 1.641 0 0 1-1.641-1.644Z"
        fill="#7fcfd9"
      />
    </g>
  </svg>
)
