import React from 'react'
import { HiArrowNarrowRight } from 'react-icons/hi'
import farmapayPos from 'assets/images/farmapay-pos.jpg'

export const FarmapayPOS = () => {
  return (
    <article id="pos" className="w-full bg-tertiary">
      <div
        className="
      tablet:container
      desktop:container
      mx-auto
      flex
      mobile:flex-col
      tablet:flex-row
      tablet:gap-x-4
      mobile:px-6
      tablet:px-0
      mobile:py-14
      tablet:py-20
      desktop:py-24
      relative"
      >
        <img
          src={farmapayPos}
          alt="farmapay NFC"
          className="mobile:mx-auto desktop:mx-0 tablet:flex-1 tablet:order-2 desktop:max-w-sm tablet:w-2/4 tablet:h-2/4"
        />

        <div className="flex flex-col tablet:flex-1 tablet:order-1 tablet:gap-y-6 tablet:justify-center">
          <h3 className="mobile:absolute tablet:static mobile:top-8 tablet:top-0 font-medium mobile:text-sm tablet:text-3xl desktop:text-4xl text-primary">
            Venda em todo lugar
            <br />
            <span className="text-secondary mobile:text-lg tablet:text-3xl desktop:text-4xl">
              A solução mais fácil de
              <br />
              pagamentos para a sua empresa
            </span>
          </h3>
          <a
            href="#name"
            className="flex
      items-center
      mobile:justify-center
      tablet:justify-start
      gap-x-2
      text-primary
      mobile:text-sm
      tablet:text-lg
      font-medium
      w-full"
          >
            Cadastre-se já <HiArrowNarrowRight className="w-5 h-5" />
          </a>
        </div>
      </div>
    </article>
  )
}
