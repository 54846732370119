import React from "react";
import { MicrobankLogo } from "components/svgs/MicrobankLogo";
import { Logo } from "components/svgs/Logo";
import { FacebookIcon } from "components/svgs/FacebookIcon";
import { InstagramIcon } from "components/svgs/InstagramIcon";
import { TwitterIcon } from "components/svgs/TwitterIcon";
import { YoutubeIcon } from "components/svgs/YoutubeIcon";
import { Link } from "react-router-dom";
import PlayStoreImg from "assets/images/play-store.png";
import AppStoreImg from "assets/images/app-store.png";

const forYou = [
  { text: "Para você", link: "/#for-you" },
  { text: "Cartão pré-pago Farmapay", link: "/#cards", type: "YOU" },
  { text: "Pague com QR Code", link: "/#payment", type: "YOU" },
  {
    text: "Parcele boletos, recarregue seu celular",
    link: "/#uncomplicate",
    type: "YOU",
  },
  { text: "Ganhe Cashback e cupons", link: "/#coupon-cashback", type: "YOU" },
  {
    text: "Transfira com foto e áudio ou com Pix",
    link: "/#transfers",
    type: "YOU",
  },
  { text: "Controle suas finanças", link: "/#financial-control", type: "YOU" },
];

const forBusiness = [
  { text: "Para o seu negócio", link: "/#for-business", type: "BUSINESS" },
  { text: "Pagamento com Maquininha", link: "/#pos", type: "BUSINESS" },
  { text: "Pagamento com QR Code", link: "/#payment", type: "BUSINESS" },
  { text: "Tap on phone", link: "/#tap-and-go", type: "BUSINESS" },
  {
    text: "Pra todo tipo de negócio",
    link: "/#all-business",
    type: "BUSINESS",
  },
  { text: "Tarifas e taxas", link: "/#best-rates", type: "BUSINESS" },
  { text: "Cadastre-se", link: "/#name", type: "BUSINESS" },
];

const transparency = [
  { text: "Transparência", link: "/" },
  { text: "Termos de Uso", link: "/" },
  { text: "Política de Privacidade", link: "/politica-de-privacidade" },
  { text: "Compliance", link: "/" },
  { text: "Contratos", link: "/" },
  { text: "Covid", link: "/" },
];

const talkToUs = [
  { text: "Fale com a gente", link: "/" },
  { text: "0800 888 4444", link: "/" },
  { text: "atendimento@farmapay.com.br", link: "/" },
  { text: "Atendimento 24h", link: "/" },
];

const ombudsman = [
  { text: "Ouvidoria", link: "/" },
  { text: "0800 888 4444", link: "/" },
  { text: "ouvidoria@farmapay.com.br", link: "/" },
  { text: "Atendimento das 10h às 16h (dias úteis)", link: "/" },
];

export const Footer = () => {
  return (
    <footer className="w-full bg-secondary desktop:pt-2 z-0">
      <article className="w-full tablet:container desktop:mx-auto mobile:px-6 tablet:pl-6 tablet:pr-0 mobile:py-14 desktop:px-0 desktop:py-16 flex mobile:flex-col desktop:flex-row flex-wrap ice">
        <article className="w-full grid grid-cols-6 mobile:gap-y-8 mobile:mt-8 desktop:gap-x-14">
          <div
            className="flex flex-col mobile:items-center tablet:items-start gap-y-4  mobile:col-span-full
              tablet:col-span-3
              desktop:col-span-2"
          >
            <Logo variant="DARK" className="text-tertiary fill-tertiary" />
            <span className="text-light-2 font-medium text-sm ml-12 -mt-4">
              O jeito fácil de pagar
            </span>
          </div>

          <div
            className="flex
            items-center
            mobile:justify-center
            tablet:justify-start
            mobile:gap-x-10
            mobile:col-span-full
            tablet:col-span-3
            desktop:col-span-2
            desktop:col-end-7
            "
          >
            <FacebookIcon />
            <InstagramIcon />
            <TwitterIcon />
            <YoutubeIcon />
          </div>

          <Link
            to="/#start"
            className="font-medium mobile:text-sm text-quinary w-full col-span-full"
          >
            Início
          </Link>

          {[forYou, forBusiness].map((item, index) => (
            <ul
              key={index}
              className="w-full
              mobile:max-w-xs
              desktop:max-w-none
              flex
              flex-col
              items-start
              mobile:gap-y-4
              mobile:col-span-full
              tablet:col-span-3
              desktop:col-span-2"
            >
              {item.map(({ link, text, type }) => (
                <li
                  key={text}
                  className="text-quinary first:text-error font-medium mobile:text-sm"
                >
                  <a href={link} className="">
                    {text}
                  </a>
                </li>
              ))}
            </ul>
          ))}

          {[transparency, talkToUs, ombudsman].map((item, index) => (
            <ul
              key={index}
              className="w-full
              mobile:max-w-xs
              desktop:max-w-none
              flex
              flex-col
              items-start
              mobile:gap-y-4
              mobile:col-span-full
              tablet:col-span-3
              desktop:col-span-2"
            >
              {item.map(({ link, text }) => (
                <li
                  key={text}
                  className="text-quinary first:text-error font-medium mobile:text-sm"
                >
                  <Link to={link} className="">
                    {text}
                  </Link>
                </li>
              ))}
            </ul>
          ))}

          <ul
            className="mobile:w-full
          mobile:max-w-xs
          desktop:max-w-none
          flex
          flex-col
          items-start
          mobile:gap-y-4
          mobile:col-span-full
          tablet:col-span-3
          desktop:col-span-2"
          >
            <li className="text-error font-medium mobile:text-sm">
              Baixe o app
            </li>
            <li className="">
              <Link to="/" className="">
                <img
                  src={PlayStoreImg}
                  alt="Play Store"
                  className="object-cover border border-quinary rounded-md"
                />
              </Link>
            </li>
            <li className="">
              <Link to="/" className="">
                <img
                  src={AppStoreImg}
                  alt="App Store"
                  className="object-cover border border-quinary rounded-md"
                />
              </Link>
            </li>
          </ul>

          <span className="text-quaternary col-span-full">
            &copy; Farmapay, 2021
          </span>
        </article>
      </article>

      <div className=" bg-light w-full">
        <div className="tablet:container tablet:mx-auto flex mobile:flex-col tablet:flex-row items-start justify-between w-full mobile:px-6 mobile:py-8 gap-y-6">
          <MicrobankLogo className="mobile:ml-0 tablet:-ml-6" />

          <p className="font-light mobile:text-sm flex flex-col items-start text-quaternary">
            <span className="">R. Teófilo David Müzel, 639</span>
            <span className="">Vila Ophelia, Itapeva - SP, 18400-816</span>
            <span className="">CNPJ: 35.041.756/0001-63</span>
          </p>
        </div>
      </div>
    </footer>
  );
};
