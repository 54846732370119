import React from 'react'

interface Props {
  section: 'YOU' | 'BUSINESS'
}

export const SelectedSection = ({ section }: Props) => {
  return (
    <div
      className={`${
        section === 'YOU' ? 'translate-x-0' : 'translate-x-full'
      } absolute block bottom-0 transform transition-all duration-300 w-1/2 z-0`}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="156"
        height="40"
        viewBox="0 0 156 40"
        className="tablet:hidden w-full"
      >
        <path
          id="Path_54004"
          data-name="Path 54004"
          d="M21109.635,18166.994H20967a12.322,12.322,0,0,0,9.451-4.691,17.6,17.6,0,0,0,3.906-11.059V18143c0-8.82,5.992-16,13.359-16h102.561c7.367,0,13.365,7.178,13.365,16v8.248a17.58,17.58,0,0,0,3.9,11.059,12.321,12.321,0,0,0,9.455,4.691Z"
          transform="translate(-20967.004 -18126.998)"
          fill="#fff"
        />
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="304.004"
        height="48"
        viewBox="0 0 304.004 48"
        className="mobile:hidden tablet:flex w-full"
      >
        <path
          id="Path_54004"
          data-name="Path 54004"
          d="M21254.961,18175H20967a14.789,14.789,0,0,0,11.346-5.631,21.135,21.135,0,0,0,4.688-13.271v-9.9c0-10.584,7.188-19.2,16.031-19.2h239.871c8.84,0,16.035,8.613,16.035,19.2v9.9a21.135,21.135,0,0,0,4.688,13.271A14.789,14.789,0,0,0,21271,18175Z"
          transform="translate(-20967 -18126.998)"
          fill="#fff"
        />
      </svg>
    </div>
  )
}
