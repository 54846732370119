import { FarmapayLogo } from 'assets/images/FarmapayLogo'
import React, { useState } from 'react'
import { HiArrowNarrowRight } from 'react-icons/hi'
import { Link } from 'react-router-dom'
import { Swiper as SwiperInterface } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import manUsingPhone from 'assets/images/man-using-phone.png'
import womanPhoneRecharge from 'assets/images/woman-phone-recharge.png'
import expenseControl from 'assets/images/expense-control.png'

export const FarmapayUncomplicate = () => {
  const [activeSwiper, setActiveSwiper] = useState(0)

  const onActiveIndexChange = (swiper: SwiperInterface) =>
    setActiveSwiper(swiper.activeIndex)

  return (
    <article
      id="uncomplicate"
      className="w-full flex flex-col mobile:py-14 tablet:pt-24 desktop:pb-32 bg-tertiary"
    >
      <h3
        className="mobile:text-lg
      tablet:text-3xl
      desktop:text-4xl
      text-quaternary
      font-medium
      w-full
      mobile:px-6
      tablet:px-0
      mobile:mb-4
      tablet:mb-14
      tablet:text-center"
      >
        Descomplique com <span className="text-primary">Farmapay</span>
      </h3>
      <Swiper
        spaceBetween={12}
        allowTouchMove
        slidesPerView="auto"
        onActiveIndexChange={onActiveIndexChange}
        className="w-full first-swiper"
      >
        <SwiperSlide className="flex items-end justify-end bg-light rounded-2xl relative">
          <p className="absolute left-4 top-6 font-bold text-2xl z-10">
            Parcele seus
            <br />
            boletos em 12x
            <br />
            no cartão
          </p>
          <span className="absolute mobile:bottom-4 tablet:bottom-3 left-4 mobile:text-sm tablet:text-lg font-medium text-secondary z-10">
            É rápido!
          </span>
          <img
            src={manUsingPhone}
            alt="Parcele seus boletos"
            className="h-44 w-32 z-10"
          />
          <FarmapayLogo className="absolute mobile:bottom-4 mobile:left-9 w-32 h-32 z-0 fill-light-2" />
        </SwiperSlide>
        <SwiperSlide className="flex items-end justify-start bg-light rounded-2xl relative">
          <p className="absolute top-6 w-full text-center font-bold text-2xl z-20">
            Recarregue seu
            <br />
            celular, vale
            <br />
            transporte e mais!
          </p>
          <Link
            to="/"
            className="absolute mobile:bottom-4 tablet:bottom-3 right-4 ml-auto flex items-center justify-center gap-x-1 mobile:text-sm tablet:text-lg font-medium text-secondary z-10"
          >
            Carregue já <HiArrowNarrowRight className="w-4 h-4" />
          </Link>
          <img
            src={womanPhoneRecharge}
            alt="Recarga de celular"
            className="z-10"
          />
          <FarmapayLogo
            className="absolute mobile:left-8 mobile:top-8 tablet:top-4 ml-8 h-full w-full transform scale-125 z-0 fill-error opacity-10 flex items-center justify-center"
            viewBox="0 0 221 214"
          />
        </SwiperSlide>
        <SwiperSlide className="flex items-end bg-light rounded-2xl relative overflow-hidden mobile:mr-6 desktop:mr-0">
          <p className="absolute right-4 top-6 text-right font-bold text-2xl text-green">
            Metas smarts
            <br />
            e controle de
            <br />
            gastos
          </p>
          <span className="absolute mobile:bottom-4 tablet:bottom-3 right-4 mobile:text-sm tablet:text-lg font-medium ml-auto text-tertiary ">
            É Fácil!
          </span>
          <img
            src={expenseControl}
            alt="Controle de gastos"
            className="flex-1 origin-cover"
          />
          <FarmapayLogo
            className="absolute -top-48 -mr-80 z-0 transform scale-75 fill-green opacity-10 flex items-start justify-end"
            viewBox="0 0 154 150"
          />
        </SwiperSlide>
      </Swiper>

      <div className="w-full mobile:flex items-center justify-center gap-x-2 mt-4 tablet:hidden">
        <div
          className={`${
            activeSwiper === 0 ? 'w-4' : 'w-2'
          } h-2 bg-light-2 rounded-full transition-all duration-300`}
        ></div>
        <div
          className={`${
            activeSwiper === 1 ? 'w-4' : 'w-2'
          } h-2 bg-light-2 rounded-full transition-all duration-300`}
        ></div>
        <div
          className={`${
            activeSwiper === 2 ? 'w-4' : 'w-2'
          } h-2 bg-light-2 rounded-full transition-all duration-300`}
        ></div>
      </div>
    </article>
  )
}
