import React from 'react'
import { FarmapayActivities } from 'assets/images/FarmapayActivities'
import womanCouponCashback from 'assets/images/woman-coupon-cashback.jpg'

export const FaarmapayCouponCashback = () => (
  <article id="coupon-cashback" className="w-full coupon-cashback-container">
    <div
      className="
  flex
  mobile:flex-col
  tablet:flex-row
  mobile:items-start
  tablet:items-center
  tablet:justify-center
  tablet:container
  tablet:mx-auto
  desktop:container
  relative
  "
    >
      <div className="flex flex-col items-start mobile:gap-y-4.5 w-full tablet:flex-1 z-10 mobile:pt-6 mobile:px-6 tablet:p-0">
        <h3 className="mobile:text-2xl tablet:text-4xl font-medium w-full text-tertiary">
          Ganhe <br className="mobile:flex tablet:hidden" />
          <span className="text-primary">
            cashback e<br /> cupons
          </span>{' '}
          de <br className="mobile:flex tablet:hidden" /> desconto{' '}
          <br className="mobile:hidden tablet:flex" />
          no <br className="mobile:flex tablet:hidden" /> Farmapay
        </h3>
        <FarmapayActivities />
      </div>
      <img
        src={womanCouponCashback}
        alt="Cupom e Cashback"
        className="h-full tablet:w-full block mobile:absolute tablet:right-0 z-0"
      />
    </div>
  </article>
)
