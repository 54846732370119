/* eslint-disable react/prop-types */
import React from 'react'

export const PrimaryButton = ({
  children,
  className,
  onClick,
  ...props
}: React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>) => {
  const rippleRef = React.useRef<HTMLDivElement>(null)

  const rippleClassName = 'ripple'

  const handleOnClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    rippleRef.current!.className = `${rippleClassName} ripple__animation`

    onClick?.(event)

    setTimeout(() => {
      rippleRef.current!.className = rippleClassName
    }, 300)
  }

  return (
    <button
      onClick={handleOnClick}
      className={`
    border-none
    outline-none
    cursor-pointer
    flex
    items-center
    justify-center
    bg-primary
    text-tertiary
    font-semibold
    rounded-3xl
    overflow-hidden
    relative
    transition-all
    duration-300
    ${className} ${props.disabled ? 'bg-opacity-40 text-opacity-80' : ''}`}
      {...props}
    >
      <div ref={rippleRef} className={rippleClassName} />
      {children}
    </button>
  )
}
