import React from 'react'
import PlayStoreImg from 'assets/images/play-store.png'
import AppStoreImg from 'assets/images/app-store.png'

export const FarmapayDownloadNow = () => (
  <article id="download" className="w-full bg-tertiary">
    <div
      className="mobile:w-full
tablet:container
tablet:mx-auto
flex
mobile:flex-col
tablet:flex-row
mobile:items-center
tablet:items-start
justify-between
gap-y-12
mobile:py-16
mobile:px-6
tablet:px-0
desktop:pt-24
desktop:pb-32"
    >
      <h4 className="font-bold mobile:text-3xl text-secondary mobile:text-center tablet:text-left tablet:flex-1 tablet:hidden leading-10">
        <span className="text-primary">
          Baixe agora todas <br /> as facilidades
        </span>{' '}
        do Farmapay
      </h4>
      <h4 className="font-bold mobile:hidden tablet:block tablet:text-3xl desktop:text-5xl tablet:text-left leading-10">
        <span className="text-primary">
          Baixe agora todas as
          <br />
          facilidades do
        </span>{' '}
        <br />
        Farmapay
      </h4>

      <article className="flex flex-col mobile:items-center tablet:items-start justify-center mobile:gap-y-6 tablet:gap-y-8">
        <div
          className="w-full
    flex
    mobile:flex-row
    tablet:flex-col
    mobile:items-center
    tablet:items-start
    justify-center
    gap-x-3
    gap-y-8
    tablet:order-2"
        >
          <img src={PlayStoreImg} alt="Play Store" className="" />
          <img src={AppStoreImg} alt="App Store" className="" />
        </div>

        <span className="tablet:order-1 font-medium mobile:text-2xl tablet:text-3xl text-secondary">
          É fácil, é só baixar!
        </span>
      </article>
    </div>
  </article>
)
