import React, { useEffect, useRef } from "react";
import { Header } from "components/Header";
import { Footer } from "components/Footer";
import manBackgorundBlue from "assets/images/man-background-blue.png";
import farmapayHome from "assets/images/farmapay-home.png";
import { SelectedSection } from "./SelectedSection";
import { LinkButton } from "components/LinkButton";
import { HiArrowNarrowDown, HiArrowNarrowRight } from "react-icons/hi";
import "./styles.css";
import "swiper/css";
import { Link, useLocation } from "react-router-dom";
import { useHome } from "hooks/useHome";
import { ForYou } from "./ForYou";
import { ForBusiness } from "./ForBusiness";

import { Helmet } from "react-helmet";

export const LandingPage = () => {
  const { section, setSection } = useHome();
  const location = useLocation();

  const carouselRef = useRef<HTMLDivElement>(null);
  const mainSectionRef = useRef<HTMLDivElement>(null);

  const onChangeSection = () =>
    setTimeout(() => {
      const forYou = carouselRef.current?.children[0];
      const forBusiness = carouselRef.current?.children[1];

      if (section === "YOU") {
        mainSectionRef.current!.style.height =
          mainSectionRef.current!.scrollHeight -
          carouselRef.current!.scrollHeight +
          forYou!.scrollHeight +
          "px";
      } else {
        mainSectionRef.current!.style.height =
          mainSectionRef.current!.scrollHeight -
          carouselRef.current!.scrollHeight +
          forBusiness!.scrollHeight +
          "px";
      }
    }, 1000);

  useEffect(() => {
    onChangeSection();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", () => {
      onChangeSection();
    });
    onChangeSection();

    return () => {
      window.removeEventListener("resize", () => {});
    };
  }, [section]);

  useEffect(() => {
    const hashes: { [key: string]: Function } = {
      "#for-you": () => setSection("YOU"),
      "#download": () => setSection("YOU"),
      "#start": () => {
        document
          .querySelector("#start")
          ?.scrollIntoView({ behavior: "smooth" });
      },
      "#name": () => {
        setSection("BUSINESS");
      },
      "#for-business": () => {
        setSection("BUSINESS");
      },
    };

    if (location.hash) hashes?.[location.hash]?.();
  }, [location]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Farmapay</title>
      </Helmet>
      <main className="w-full bg-tertiary mobile:pb-20 tablet:pb-0 overflow-x-hidden">
        <Header />

        <section className="w-full flex flex-col mobile:mt-16 tablet:mt-24 desktop:mt-48 mobile:pb-14 desktop:pb-12">
          <div
            id="start"
            className="flex overflow-visible w-full mobile:bg-tertiary tablet:bg-light z-30"
          >
            <article
              className="flex-1
          overflow-visible
          tablet:container
          desktop:container
          tablet:mx-auto
          flex
          mobile:flex-col
          tablet:flex-row
          tablet:gap-x-4
          relative"
            >
              <div
                className="tablet:order-2 tablet:flex-1 flex h-96 relative"
                id="main-image-container"
              >
                <img
                  src={manBackgorundBlue}
                  alt="Pagar e receber"
                  className="tablet:inset-0 flex mobile:rounded-2xl object-cover object-center mobile:relative tablet:absolute w-full h-full main-image"
                />
              </div>
              <div className="tablet:order-1 tablet:flex-1 flex flex-col items-start tablet:mt-12 tablet:mb-0 desktop:my-24">
                <h1
                  className="mobile:absolute
              tablet:static
              mobile:top-6
              mobile:left-6
              font-semibold
              mobile:text-2xl
              tablet:text-3xl
              desktop:text-5xl
              mobile:text-tertiary
              tablet:text-secondary"
                >
                  O jeito fácil <br /> de pagar
                </h1>

                <p className="text-secondary font-medium mobile:text-lg desktop:text-2xl mobile:mx-6 mobile:mt-6 mobile:mb-14 tablet:mx-0">
                  Crie sua{" "}
                  <span className="text-primary">carteira digital grátis.</span>
                  <br />
                  {""}
                  Vem, é fácil!
                </p>

                <article
                  className="w-full
              mobile:bg-tertiary
              tablet:bg-light
              mobile:px-6
              mobile:pt-2
              mobile:pb-4
              tablet:p-0
              flex
              mobile:items-stretch
              tablet:items-start
              mobile:fixed
              mobile:bottom-0
              tablet:static"
                >
                  <LinkButton
                    to="#name"
                    className="mobile:p-4.5 tablet:p-5.5 gap-x-2 mobile:text-base desktop:text-2xl mobile:w-full tablet:w-auto"
                  >
                    Quero ser Farmapay{" "}
                    <HiArrowNarrowRight className="mobile:w-5 mobile:h-5 tablet:w-6 tablet:h-6" />
                  </LinkButton>
                </article>
              </div>
            </article>
          </div>

          <article className="w-full flex mobile:flex-col tablet:flex-row gap-y-3 gap-x-4 items-center tablet:container desktop:container tablet:mx-auto tablet:mt-24">
            <img
              alt="Farmapay Home"
              src={farmapayHome}
              className="flex-1 tablet:max-w-xs desktop:max-w-md"
            />

            <div className="flex flex-col gap-y-6 items-start mobile:mx-6 tablet:mx-0 flex-1">
              <h2 className="text-secondary font-medium mobile:text-lg tablet:text-3xl desktop:text-4xl text-left">
                Na <span className="text-primary">Farmapay</span> você tem uma
                carteira digital para você e para o seu negócio
              </h2>

              <Link
                to="/"
                className="text-primary text-lg font-medium mobile:hidden tablet:flex items-center gap-x-2"
              >
                Baixe o app <HiArrowNarrowRight className="w-5 h-5" />
              </Link>
            </div>
          </article>
        </section>

        <section
          ref={mainSectionRef}
          className="w-full flex flex-col bg-secondary overflow-hidden"
        >
          <h3 className="flex-1 text-tertiary font-medium mobile:text-lg tablet:text-3xl flex items-center justify-center gap-x-2 mobile:mx-6 mobile:my-8">
            Toque e veja as vantagens para{" "}
            <HiArrowNarrowDown className="mobile:w-5 tablet:w-8 mobile:h-5 tablet:h-8 text-primary" />
          </h3>

          <div className="flex mobile:flex-1 tablet:container mobile:mx-6 tablet:mx-auto relative">
            <SelectedSection section={section} />
            <button
              className={`${
                section === "YOU"
                  ? "text-primary font-bold"
                  : "text-tertiary font-medium"
              } flex items-center justify-center flex-1 mobile:text-sm tablet:text-2xl h-10 transition-all duration-300 z-20`}
              onClick={() => setSection("YOU")}
            >
              Você
            </button>
            <button
              className={`${
                section === "BUSINESS"
                  ? "text-primary font-bold"
                  : "text-tertiary font-medium"
              } flex items-center justify-center flex-1 mobile:text-sm tablet:text-2xl h-10 transition-all duration-300 z-20`}
              onClick={() => setSection("BUSINESS")}
            >
              Seu negócio
            </button>
          </div>

          <div
            ref={carouselRef}
            id="sections-container"
            className={`overflow-visible carousel flex bg-tertiary transition-all duration-300 transform relative w-full ${
              section === "YOU" ? "translate-x-0" : "-translate-x-full"
            }`}
          >
            <ForYou />

            <ForBusiness />
          </div>
        </section>

        <Footer />
      </main>
    </>
  );
};
