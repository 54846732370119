import axios, { AxiosInstance } from 'axios'
import { UfInterface, CityInterface } from './types'

export class BrasilService {
  private api: AxiosInstance

  constructor() {
    this.api = axios.create({
      baseURL: 'https://brasilapi.com.br/api'
    })
  }

  async getCities(uf: string) {
    return await this.api.get<CityInterface[]>(`/ibge/municipios/v1/${uf}`)
  }

  async getStates() {
    return await this.api.get<UfInterface[]>('/ibge/uf/v1')
  }
}
