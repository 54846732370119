import React, { SVGProps } from 'react'

export const YoutubeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} {...props}>
    <path data-name="Ret\xE2ngulo 4989" fill="none" d="M0 0h32v32H0z" />
    <path
      data-name="Uni\xE3o 5"
      d="M6.667 26.667a4 4 0 0 1-4-4V9.334a4.007 4.007 0 0 1 4-4h18.665a4.006 4.006 0 0 1 4 4v13.332a4 4 0 0 1-4 4ZM5.332 9.334v13.332a1.335 1.335 0 0 0 1.335 1.332h18.665a1.334 1.334 0 0 0 1.335-1.332V9.334a1.335 1.335 0 0 0-1.335-1.33H6.667a1.336 1.336 0 0 0-1.335 1.33Zm8 10.243v-7.161c0-.315.245-.518.525-.357l6.418 3.67a.4.4 0 0 1 .19.345v.027a.405.405 0 0 1-.238.349l-6.368 3.5a.369.369 0 0 1-.184.052.377.377 0 0 1-.344-.421Z"
      fill="#7fcfd9"
    />
  </svg>
)
