import React from 'react'
import { HiArrowNarrowRight } from 'react-icons/hi'
import { Link } from 'react-router-dom'
import farmapayLogoWoman from 'assets/images/farmapay-logo-woman.png'

export const FarmapayFinancialControl = () => (
  <article
    id="financial-control"
    className="w-full mobile:bg-tertiary tablet:bg-light border-none"
  >
    <div
      className="w-full
    desktop:container
    tablet:container
    tablet:py-24
    tablet:mx-auto
    flex
    mobile:flex-col
    desktop:flex-row
    desktop:gap-x-4
    tablet:gap-y-8
    items-center
    relative"
    >
      <img
        src={farmapayLogoWoman}
        alt="Farmapay"
        className="mobile:mx-auto tablet:block tablet:mx-0 z-10 desktop:order-2 mobile:flex-1 tablet:w-80 mobile:max-w-xs desktop:max-w-none object-cover"
      />

      <div
        className="mobile:w-full
  tablet:w-auto
  tablet:flex-1
  mobile:bg-secondary
  tablet:bg-light
  flex
  flex-col
  mobile:gap-y-2
  mobile:px-6
  mobile:pb-6
  mobile:pt-20
  mobile:-mt-14
  tablet:mt-0
  tablet:p-0             
  z-0
  desktop:order-1"
      >
        <p className="mobile:text-lg tablet:text-3xl desktop:text-4xl mobile:font-medium mobile:text-tertiary tablet:text-secondary">
          Com o painel financeiro <br />
          <span className="text-primary">Farmapay</span> você controla <br />
          suas contas de um jeito <br /> simples e prático.{' '}
        </p>
        <Link
          to="/"
          className="flex items-center gap-x-2 text-center font-medium mobile:text-sm tablet:text-lg text-primary"
        >
          Baixe o app e conheça mais{' '}
          <HiArrowNarrowRight className="text-primary w-5 h-5" />
        </Link>
      </div>
    </div>
  </article>
)
