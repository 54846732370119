import React, { useState } from 'react'
import { Swiper as SwiperInterface } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import meiBusinsess from 'assets/images/mei-business.png'
import smallBusinsess from 'assets/images/small-business.png'
import largeBusinsess from 'assets/images/large-business.png'
import { HiArrowNarrowRight } from 'react-icons/hi'
import { Link } from 'react-router-dom'

export const AllBusiness = () => {
  const [activeSwiper, setActiveSwiper] = useState(0)

  const onActiveIndexChange = (swiper: SwiperInterface) =>
    setActiveSwiper(swiper.activeIndex)

  return (
    <article id="all-business" className="bg-tertiary mobile:pt-14 font-medium">
      <div className="w-full  desktop:container tablet:pl-20 tablet:pr-4 desktop:mx-auto mobile:px-3 desktop:px-0">
        <h3 className="mobile:text-lg tablet:text-3xl desktop:text-4xl text-left text-quaternary mobile:mb-4">
          Aqui é pra todo{' '}
          <span className="text-secondary">tipo de negócio</span>
        </h3>

        <Swiper
          spaceBetween={12}
          allowTouchMove
          slidesPerView="auto"
          onActiveIndexChange={onActiveIndexChange}
          className="w-full third-swiper"
        >
          <SwiperSlide className="flex flex-col relative overflow-hidden rounded-2xl bg-light ">
            <span className="absolute mobile:px-4 mobile:pt-6 font-bold mobile:text-xl tablet:text-2xl text-secondary">
              Microempreendedor Individual (MEI)
            </span>
            <img
              src={meiBusinsess}
              alt="Microempreendedor Individual"
              className="w-full flex-1 rounded-2xl"
            />
            <ul className="flex flex-col items-start mobile:text-xs tablet:text-sm list-disc list-outside text-primary flex-1 mobile:px-4 mobile:pt-4 mobile:pb-6">
              <li className="ml-3">
                <span className="text-secondary">
                  Venda no crédito ou débito sem maquininhas;
                </span>
              </li>
              <li className="ml-3">
                <span className="text-secondary">
                  Veja todas as transações em tempo real;
                </span>
              </li>
              <li className="ml-3">
                <span className="text-secondary">
                  Receba automaticamente na sua conta bancária;
                </span>
              </li>
              <li className="ml-3">
                <span className="text-secondary">
                  Pegue seu CNPJ, um endereço no seu nome e cadastre-se agora
                </span>
              </li>

              <Link
                to="/"
                className="text-primary mobile:text-sm font-bold flex items-center gap-x-2 mobile:mt-2"
              >
                Cadastre-se já{' '}
                <HiArrowNarrowRight className="h-4 w-4 text-primary" />
              </Link>
            </ul>
          </SwiperSlide>
          <SwiperSlide className="flex flex-col relative overflow-hidden rounded-2xl bg-light">
            <span className="absolute mobile:px-4 mobile:pt-6 font-bold mobile:text-xl tablet:text-2xl text-tertiary">
              Pequenas <br /> empresas
            </span>
            <img
              src={smallBusinsess}
              alt="Pequenos negócios"
              className="w-full flex-1 rounded-2xl"
            />
            <ul className="flex flex-col items-start mobile:text-xs tablet:text-sm list-disc list-outside text-primary flex-1 mobile:px-4 mobile:pt-4 mobile:pb-6">
              <li className="ml-3">
                <span className="text-secondary">
                  Venda no crédito ou débito sem maquininhas;
                </span>
              </li>
              <li className="ml-3">
                <span className="text-secondary">
                  Veja todas as transações em tempo real;
                </span>
              </li>
              <li className="ml-3">
                <span className="text-secondary">
                  Receba automaticamente na sua conta bancária;
                </span>
              </li>
              <li className="ml-3">
                <span className="text-secondary">
                  Pegue seu CNPJ, um endereço no seu nome e cadastre-se agora
                </span>
              </li>

              <Link
                to="/"
                className="text-primary mobile:text-sm font-bold flex items-center gap-x-2 mobile:mt-2"
              >
                Cadastre-se já{' '}
                <HiArrowNarrowRight className="h-4 w-4 text-primary" />
              </Link>
            </ul>
          </SwiperSlide>
          <SwiperSlide className="flex flex-col relative overflow-hidden rounded-2xl bg-light tablet:mr-4 desktop:mr-0">
            <span className="absolute mobile:px-4 mobile:pt-6 font-bold mobile:text-xl tablet:text-2xl text-tertiary">
              Grande empresas
            </span>
            <img
              src={largeBusinsess}
              alt="Grandes negócios"
              className="w-full flex-1 rounded-2xl"
            />
            <ul className="flex flex-col items-start mobile:text-xs tablet:text-sm list-disc list-outside text-primary flex-1 mobile:px-4 mobile:pt-4 mobile:pb-6">
              <li className="ml-3">
                <span className="text-secondary">
                  Venda no crédito ou débito sem maquininhas;
                </span>
              </li>
              <li className="ml-3">
                <span className="text-secondary">
                  Veja todas as transações em tempo real;
                </span>
              </li>
              <li className="ml-3">
                <span className="text-secondary">
                  Receba automaticamente na sua conta bancária;
                </span>
              </li>
              <li className="ml-3">
                <span className="text-secondary">
                  Pegue seu CNPJ, um endereço no seu nome e cadastre-se agora
                </span>
              </li>

              <Link
                to="/"
                className="text-primary mobile:text-sm font-bold flex items-center gap-x-2 mobile:mt-2"
              >
                Cadastre-se já{' '}
                <HiArrowNarrowRight className="h-4 w-4 text-primary" />
              </Link>
            </ul>
          </SwiperSlide>

          <div className="w-full mobile:flex items-center justify-center gap-x-2 mt-4 tablet:hidden">
            <div
              className={`${
                activeSwiper === 0 ? 'w-4' : 'w-2'
              } h-2 bg-light-2 rounded-full transition-all duration-300`}
            ></div>
            <div
              className={`${
                activeSwiper === 1 ? 'w-4' : 'w-2'
              } h-2 bg-light-2 rounded-full transition-all duration-300`}
            ></div>
            <div
              className={`${
                activeSwiper === 2 ? 'w-4' : 'w-2'
              } h-2 bg-light-2 rounded-full transition-all duration-300`}
            ></div>
          </div>
        </Swiper>
      </div>
    </article>
  )
}
