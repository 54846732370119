import React, { useRef, useState } from 'react'
import { Controller, Control, RegisterOptions } from 'react-hook-form'
import { HiChevronDown, HiChevronUp } from 'react-icons/hi'
import InputMask from 'react-input-mask'

interface Option {
  label: string
  value: string
}

interface Props
  extends React.DetailedHTMLProps<
    React.LabelHTMLAttributes<HTMLLabelElement>,
    HTMLLabelElement
  > {
  name: string
  control: Control
  rules?: RegisterOptions
  type?: React.HTMLInputTypeAttribute
  datalist?: Option[]
  mask?: string
  label: string
}

export const TextInput = ({
  name,
  control,
  rules,
  type,
  className,
  label,
  mask,
  datalist,
  ...props
}: Props) => {
  const localRef = useRef<HTMLInputElement | null>(null)
  const [isOpen, setIsOpen] = useState(false)

  const datalistMap = (
    array: typeof datalist,
    onChange: (event: any) => void
  ) => {
    return (
      array && (
        <>
          {array.map((option, index) => (
            <label
              key={`${name}-${option.value}-${index}`}
              htmlFor={`${name}-${option.value}-${index}`}
              className="appearance-none outline-none focus:outline-none w-full text-left p-3 text-sm text-quaternary cursor-pointer"
            >
              <input
                id={`${name}-${option.value}-${index}`}
                type="radio"
                name={`${name}-${option.value}-${index}`}
                className="appearance-none"
                value={option.value}
                onChange={(event) => {
                  onChange(event)
                  if (isOpen) setIsOpen(false)
                }}
              />
              {option.label}
            </label>
          ))}
        </>
      )
    )
  }

  const Input = ({ inputRef, ...props }: any) => {
    return mask ? (
      <InputMask
        mask={mask}
        maskChar=""
        {...props}
        inputRef={(ref) => {
          inputRef.current = ref
          localRef.current = ref
        }}
      />
    ) : (
      <input
        {...props}
        ref={(ref) => {
          inputRef.current = ref
          localRef.current = ref
        }}
      />
    )
  }

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      rules={rules}
      render={({ field: { ref, onBlur, ...field }, fieldState }) => (
        <label
          htmlFor={name}
          className={`flex flex-col items-start relative pt-5 ${className}`}
          {...props}
        >
          <span
            onClick={(event) => {
              event.preventDefault()
              localRef.current?.focus()
            }}
            className={`absolute left-3 transition-all duration-300 cursor-text ${
              field.value
                ? 'text-us text-quinary top-0'
                : 'text-sm text-quaternary top-8'
            }`}
          >
            {label}
          </span>
          <Input
            id={name}
            type={type || 'text'}
            className={`text-sm
    p-3
    placeholder-quaternary
    text-secondary
    font-medium
    w-full
    border
    rounded-lg
    hover:ring-2
    focus:ring-2
    ring-light-2
    outline-none
    focus:outline-none
    transition-all
    duration-300 ${fieldState.error ? 'border-error' : 'border-light-2'} `}
            list={`datalist-${name}`}
            inputRef={ref}
            onBlur={() => {
              if (datalist) setIsOpen(false)
              onBlur()
            }}
            {...field}
          />
          <span className="text-error text-us h-5">
            {fieldState.error?.message}
          </span>

          <div
            className={`absolute top-14 w-full bg-tertiary border-light-2 rounded-b-lg flex flex-col transition-all duration-300 z-10 ${
              isOpen
                ? 'max-h-36 overflow-auto border-l border-b border-r mb-4'
                : 'border-0 max-h-0 overflow-hidden'
            }`}
          >
            {datalist &&
              (field.value
                ? datalistMap(
                    datalist.filter((option) =>
                      option.label
                        .toLowerCase()
                        .includes(field.value.toLowerCase())
                    ),
                    field.onChange
                  )
                : datalistMap(datalist, field.onChange))}
          </div>

          {datalist && (
            <>
              <HiChevronDown
                className={`w-6 absolute right-3 top-8 text-primary transition-all duration-300 z-0 cursor-pointer ${
                  isOpen ? 'opacity-0 h-3' : 'opacity-1 h-6'
                }`}
                onClick={() => setIsOpen((previous) => !previous)}
              />
              <HiChevronUp
                className={`w-6 absolute right-3 top-8 text-primary transition-all duration-300 z-0 cursor-pointer ${
                  isOpen ? 'opacity-1 h-6' : 'opacity-0 h-3'
                }`}
                onClick={() => setIsOpen((previous) => !previous)}
              />
            </>
          )}
        </label>
      )}
    />
  )
}
