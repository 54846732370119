import React, { SVGProps } from 'react'

export const TwitterIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} {...props}>
    <path data-name="Ret\xE2ngulo 4987" fill="none" d="M0 0h32v32H0z" />
    <path
      data-name="Uni\xE3o 10"
      d="M8 27.999a4 4 0 0 1-4-4v-16a4 4 0 0 1 4-4h16a4.009 4.009 0 0 1 4 4v16a4.008 4.008 0 0 1-4 4Zm-1.336-20v16A1.341 1.341 0 0 0 8 25.335h16a1.336 1.336 0 0 0 1.329-1.336v-16A1.331 1.331 0 0 0 24 6.671H8a1.335 1.335 0 0 0-1.336 1.328Zm7.214 13.02a6.953 6.953 0 0 1-3.748-1.1 5.85 5.85 0 0 0 .6.036 4.9 4.9 0 0 0 3.012-1.05 2.435 2.435 0 0 1-2.281-1.686 3.17 3.17 0 0 0 .453.037 2.329 2.329 0 0 0 .659-.1 2.442 2.442 0 0 1-1.973-2.382v-.025a2.492 2.492 0 0 0 1.112.3 2.438 2.438 0 0 1-1.084-2.03 2.5 2.5 0 0 1 .326-1.231 6.942 6.942 0 0 0 5.031 2.55 2.439 2.439 0 0 1 4.167-2.225 4.941 4.941 0 0 0 1.552-.6 2.461 2.461 0 0 1-1.077 1.353 5.211 5.211 0 0 0 1.4-.383 5.078 5.078 0 0 1-1.19 1.264v.309a6.9 6.9 0 0 1-6.881 6.963Z"
      fill="#7fcfd9"
    />
  </svg>
)
