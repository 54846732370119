import { useForm, UseFormProps } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

export const useYupForm = (
  validationSchema: yup.AnyObjectSchema,
  useFormProps?: UseFormProps
) =>
  useForm({
    resolver: yupResolver(validationSchema),
    ...useFormProps
  })
