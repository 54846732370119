import { HTMLMotionProps } from 'framer-motion'
import React from 'react'
import bus from 'utils/EventEmitter'

const EVENT_NAME = 'modal:toggle'

// eslint-disable-next-line no-undef
export const useModal = () => {
  const INITIAL_STATE = null

  const open = (
    Component: React.ReactNode,
    modalProps: HTMLMotionProps<'div'> = {}
  ) => {
    bus.emit(EVENT_NAME, Component, modalProps)
  }

  const close = () => {
    bus.emit(EVENT_NAME, INITIAL_STATE)
  }

  const listen = (fn: Function) => {
    bus.on(EVENT_NAME, fn)
  }

  return { open, close, listen }
}
