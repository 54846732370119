import { FarmapayFlyer } from 'assets/images/FarmapayFlyer'
import scanningQrcode from 'assets/images/scanning-qrcode.png'
import React from 'react'

export const FarmapayPayment = () => {
  return (
    <article id="payment" className="w-full bg-light-2">
      <div className="w-full tablet:container desktop:container tablet:mx-auto flex flex-col mobile:px-6 tablet:px-0 overflow-hidden">
        <h3 className="tablet:text-center font-medium mobile:text-lg tablet:text-3xl desktop:text-4xl text-secondary mobile:mt-6 tablet:mt-14">
          Com o{' '}
          <span className="mobile:text-primary tablet:text-secondary">
            Farmapay
          </span>{' '}
          você pode receber ou pagar em
          <br className="mobile:hidden desktop:inline" /> mais de{' '}
          <span className="mobile:text-secondary tablet:text-primary">
            8 milhões de pontos de venda!
          </span>
        </h3>

        <div className="w-full mobile:max-w-sm mobile:mx-auto tablet:max-w-none tablet:justify-evenly flex relative mobile:pb-9 tablet:mt-40">
          <img
            src={scanningQrcode}
            className={`mobile:absolute
          mobile:z-10
          mobile:-left-24
          mobile:-bottom-4
          tablet:inset-0
          tablet:static
          tablet:-ml-16
          tablet:-mb-1
          tablet:mt-16
          w-64
          h-64
          transform
          tablet:scale-125
          transition-all
          duration-500
          translate-x-0
          hover:translate-x-24
          `}
          />

          <FarmapayFlyer className="mobile:ml-auto tablet:-ml-12 tablet:-mt-12 transform tablet:scale-100" />
        </div>
      </div>
    </article>
  )
}
