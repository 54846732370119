import React from 'react'

interface Props {
  showMenu: boolean
}

export const ShowMenuButton = ({
  showMenu,
  ...props
}: React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> &
  Props) => (
  <button {...props}>
    <svg
      id="IconBuger"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className="transition-all duration-300"
    >
      <rect
        id="Rectangle_5880"
        data-name="Rectangle 5880"
        width="24"
        height="24"
        fill="none"
      />
      <rect
        id="Rectangle_5877"
        data-name="Rectangle 5877"
        width="16"
        height="3"
        rx="1.5"
        transform={
          showMenu ? 'translate(7.404 5.782) rotate(45)' : 'translate(4 7)'
        }
        fill="#1a1c1f"
        className="transition-all duration-300"
      />
      <rect
        id="Rectangle_5879"
        data-name="Rectangle 5879"
        width="16"
        height="3"
        rx="1.5"
        transform={
          showMenu ? 'translate(5.282 17.096)  rotate(-45)' : 'translate(4 14)'
        }
        fill="#1a1c1f"
        className="transition-all duration-300"
      />
    </svg>
  </button>
)
