import React, { SVGProps } from 'react'

const FarmapayLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      data-name="Subtra\xE7\xE3o 128"
      d="M7882.408 19170.8a24.3 24.3 0 0 1-24.274-24.258v-22.883h-22.867a24.275 24.275 0 0 1 0-48.551h22.867v-22.8a24.267 24.267 0 1 1 48.533 0v22.8h22.845a24.275 24.275 0 1 1 0 48.551h-22.845v22.883a24.293 24.293 0 0 1-24.259 24.258Zm0-95.691a24.275 24.275 0 0 0 0 48.551h23.556v-48.551Z"
      transform="translate(-7810.5 -19027.5)"
      fill={props.fill}
      style={{
        mixBlendMode: 'soft-light',
        isolation: 'isolate'
      }}
    />
  </svg>
)

export { FarmapayLogo }
