import React, { useEffect, useState } from 'react'
import { TextInput } from 'components/Form/TextInput'
import { HiArrowNarrowRight } from 'react-icons/hi'
import { SubmitHandler, useWatch } from 'react-hook-form'
import * as yup from 'yup'
import { useYupForm } from 'hooks/useYupForm'
import { Select } from 'components/Form/Select'
import { PrimaryButton } from 'components/PrimaryButton'
import { api } from 'services'
import { CityInterface, UfInterface } from 'services/Brasil/types'
import { ufs } from 'constants/uf'

interface FormData {
  name: string
  email: string
  phone: string
  cnpj: string
  uf: string
  city: string
}

export const FarmapayRegister = () => {
  const [cityOptions, setCityOptions] = useState<CityInterface[]>([])
  const [ufOptions, setUfOptions] = useState<UfInterface[] | typeof ufs>(ufs)

  const validationSchema = yup.object({
    name: yup.string().required('! Nome completo necessário'),
    email: yup
      .string()
      .email('! Digite um e-mail válido')
      .required('! E-mail necessário'),
    phone: yup
      .string()
      .required('! Celular necessário')
      .matches(/[(][0-9]{2}[)] [0-9] [0-9]{4} [0-9]{4}/, {
        message: '! Celular inválido'
      }),
    cnpj: yup
      .string()
      .required('! O CNPJ é necessário')
      .matches(/[0-9]{2}.[0-9]{3}.[0-9]{3}[/][0-9]{4}-[0-9]{2}/, {
        message: '! CNPJ inválido'
      }),
    uf: yup.string().required('! O Estado é necessário'),
    city: yup.string().required('! A Cidade é necessário')
  })

  const { control, handleSubmit, setError, clearErrors } =
    useYupForm(validationSchema)

  const watchUf = useWatch({ control, name: 'uf' })

  const getUfOptions = async () => {
    try {
      const { data, status } = await api.brasil.getStates()

      if (!!data && status === 200) {
        const sortedData = data.sort((a, b) => {
          if (a.nome < b.nome) return -1
          if (a.nome > b.nome) return 1
          return 0
        })

        setUfOptions(sortedData)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const getCityOptions = async (uf: string) => {
    try {
      const { data, status } = await api.brasil.getCities(uf)

      if (!!data && status === 200) {
        const sortedCities = data.sort((a, b) => {
          if (a.nome < b.nome) return -1
          if (a.nome > b.nome) return 1
          return 0
        })

        setCityOptions(sortedCities)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const onSubmit: SubmitHandler<FormData | { [x: string]: any }> = async ({
    city,
    cnpj,
    email,
    name,
    phone,
    uf
  }) => {
    clearErrors()

    try {
      if (uf === 'Estado') {
        setError('uf', { message: '! O Estado é necessário' })
        return
      }

      if (city === 'Cidade') {
        setError('city', { message: '! A Cidade é necessária' })
        return
      }

      await api.email.registerCompany({
        email,
        name,
        phone: phone.replace(/[^0-9]/g, ''),
        subject: `Farmapay - ${city} - ${uf}`,
        text: `CNPJ: ${cnpj}\nEnviado por Farmapay`
      })
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getUfOptions()
  }, [])

  useEffect(() => {
    if (watchUf) {
      getCityOptions(watchUf)
    }
  }, [watchUf])

  return (
    <article
      id="register"
      className="w-full bg-light mobile:px-6 tablet:px-0 mobile:pt-14 mobile:pb-12 overflow-y-visible"
    >
      <div className="w-full desktop:container tablet:container tablet:mx-auto flex mobile:flex-col tablet:flex-row tablet:gap-x-4 desktop:gap-x-20">
        <div className="mobile:w-full tablet:w-56 desktop:w-full desktop:max-w-xs flex flex-col items-start mobile:gap-y-1 tablet:gap-y-3 desktop:gap-y-6">
          <h3 className="w-full text-secondary inline font-medium mobile:text-lg tablet:text-3xl desktop:text-4xl">
            <span className="text-primary inline">Cadastre-se</span>
            <br className="mobile:hidden tablet:flex" /> e receba um contato da
            nossa equipe comercial Farmapay{' '}
            <HiArrowNarrowRight className="mobile:w-4 mobile:h-4 tablet:w-8 tablet:h-8 desktop:w-10 desktop:h-10  inline" />
          </h3>

          <span className="text-quaternary font-medium mobile:text-sm tablet:text-base desktop:text-lg">
            Conheça as possibilidades para o seu negócio
          </span>
        </div>

        <form
          className="flex-1 flex mobile:flex-col tablet:flex-row flex-wrap mobile:gap-y-2 tablet:gap-x-4"
          onSubmit={handleSubmit(onSubmit)}
        >
          <TextInput
            name="name"
            control={control}
            label="Nome completo"
            className="w-full"
          />
          <TextInput
            name="email"
            control={control}
            label="E-mail"
            type="email"
            className="w-full"
          />
          <div className="w-full flex mobile:flex-col tablet:flex-row mobile:gap-y-2 tablet:gap-x-4">
            <TextInput
              name="cnpj"
              control={control}
              label="CNPJ"
              mask="99.999.999/9999-99"
              className="flex-1"
            />
            <TextInput
              name="phone"
              control={control}
              label="Celular com DDD"
              type="tel"
              mask="(99) 9 9999 9999"
              className="mobile:w-full tablet:w-36 desktop:w-40"
            />
          </div>

          <div className="w-full flex mobile:flex-col tablet:flex-row mobile:gap-y-2 tablet:gap-x-4">
            <Select
              name="uf"
              control={control}
              defaultValue="Estado"
              options={ufOptions.map((option) => ({
                label: option.nome,
                value: option.sigla || option.nome
              }))}
              className="mobile:w-full tablet:w-36 desktop:w-40"
            />

            <TextInput
              name="city"
              control={control}
              defaultValue=""
              label="Cidade"
              datalist={cityOptions.map((option) => ({
                label: option.nome,
                value: option.nome
              }))}
              className="flex-1"
            />
          </div>

          <PrimaryButton type="submit" className="w-full p-4.5 mobile:mt-6">
            Enviar
          </PrimaryButton>
        </form>
      </div>
    </article>
  )
}
