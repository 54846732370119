import { Footer } from "components/Footer";
import { Header } from "components/Header";
import React from "react";

export const PolicyPrivacy = () => {
  return (
    <div className="w-full">
      <Header />
      <main className="w-full mobile:mt-16 tablet:mt-24 desktop:mt-48">
        <div className="bg-light mobile:px-6 mobile:py-14 tablet:px-20 tablet:py-24">
          <h1
            className="
            w-full
            max-w-2xl
            mx-auto
          text-secondary
            mobile:font-medium
            tablet:font-bold
            mobile:text-2xl
            tablet:text-3xl
            tablet:px-6"
          >
            Declaração de Política de Privacidade e Condições do{" "}
            <span className="text-primary">Farmapay</span>
          </h1>
        </div>

        <div
          className="flex flex-col
          gap-y-16
          w-full
          max-w-2xl
          mx-auto
          mobile:px-6
          mobile:pt-16
          mobile:pb-12"
        >
          <section className="w-full flex flex-col text-sm text-quaternary">
            <h2 className="text-3xl text-secondary mobile:mb-8">Introdução</h2>
            <p>
              A Política de Privacidade do Farmapay baseia-se na lei nº 13853 de
              julho de 2019, Lei Geral de Proteção de Dados Pessoais (LGPD), que
              dispõe sobre o tratamento de dados pessoais, inclusive nos meios
              digitais, por pessoa natural ou por pessoa jurídica de direito
              público ou privado, com o objetivo de proteger os direitos
              fundamentais de liberdade e de privacidade e o livre
              desenvolvimento da personalidade da pessoa natural.
              <br />
              <br />
              Ao utilizar todos os serviços e ferramentas disponíveis no Farmapay
              o usuário concorda com os termos e condições presentes no site e
              neste documento.
              <br />
              <br />
              Todas as dúvidas e sugestões são bem-vindas, entre em contato com
              o Farmapay através do site https://farmapay.com.br/ ou e-mail,
              contamos com você para melhorar cada dia mais nossas
              funcionalidades.
            </p>
          </section>

          <section className="w-full flex flex-col text-sm text-quaternary">
            <h2 className="text-2xl text-secondary mobile:mb-8">Definições</h2>
            <p>
              As definições apresentadas a seguir são importantes para o melhor
              entendimento do documento e utilização do app:
              <br />
              <br />
              Anonimização: utilização de meios técnicos que permitam que seus
              dados pessoais percam a possibilidade de associação, direta ou
              indireta, a você.
              <br />
              <br />
              Cookies: são arquivos criados por sites visitados e que são salvos
              no computador do usuário, por meio do navegador.
              <br />
              <br />
              Dados pessoais: informações pessoais que auxiliam na sua
              identificação, como nome, RG, CPF, data de nascimento, telefone,
              e-mail, endereço, dados bancários, geolocalização, fotos de
              documentos e endereço.
              <br />
              <br />
              Encarregado: pessoa indicada pelo controlador e operador para
              atuar como canal de comunicação entre o controlador, os titulares
              dos dados e a Autoridade Nacional de Proteção de Dados (ANPD).
              <br />
              <br />
              Titular, usuário ou cliente: pessoas que cadastram seus dados
              pessoais para utilizar as ferramentas que o Farmapay proporciona.
              <br />
              <br />
              Tratamento de dados pessoais: todas as atividades realizadas com
              seus dados pessoais, as que se referem as etapas de coleta,
              produção, recepção, classificação, utilização, acesso, reprodução,
              transmissão, distribuição, processamento, arquivamento,
              armazenamento, eliminação, avaliação ou controle da informação,
              modificação, comunicação e transferência.
            </p>
          </section>

          <section className="w-full flex flex-col text-sm text-quaternary">
            <h2 className="text-2xl text-secondary mobile:mb-8">Abrangência</h2>
            <p>
              Esta Política de Privacidade Farmapay abrange todos os setores
              dentro e fora da empresa, seus administradores, colaboradores,
              clientes, fornecedores e parceiros de negócio, os quais deverão
              concordar e respeitar todos os tópicos estabelecidos neste
              documento.
              <br />
              <br />
              Por meio do Canal de Denúncia, todos poderão realizar denúncias de
              atividades referente a violação da Política de Privacidade
              Farmapay.
            </p>
          </section>
          <section className="w-full flex flex-col text-sm text-quaternary">
            <h2 className="text-2xl text-secondary mobile:mb-8">
              Tratamento de Dados Pessoais
            </h2>
            <p>
              Neste tópico você encontra os dados pessoais solicitados pelo
              Farmapay durante seu cadastro no app, entre em contato com a gente
              se encontrar qualquer informação diferente das citadas nesta
              Política ou ficar em dúvida com relação ao preenchimento dos
              dados.
              <br />
              <br />
              Todas as informações requisitadas pelo Farmapay são para garantir a
              melhor segurança para todos os seus usuários e atender as
              exigências estabelecidas por órgãos reguladores do setor.
            </p>
          </section>
          <section className="w-full flex flex-col text-sm text-quaternary">
            <h2 className="text-2xl text-secondary mobile:mb-8">
              Dados pessoais solicitados pelo Farmapay
            </h2>
            <p>
              Existem duas formas de se cadastrar no Farmapay, a primeira é o
              cadastro na carteira e a segunda é o cadastro na carteira com a
              conta digital.
              <br />
              <br />
              Os dados pessoais solicitados para o cadastro na carteira são:
              nome completo, como aparece em seus documentos, data de
              nascimento, e-mail, número de celular com DDD, senha pessoal de
              seis dígitos para utilização do app e cadastro de nome do usuário,
              usercash. Você tem acesso as regras de criação de usercash através
              da área de Regulamentos no site https://farmapay.com.br/ .
              <br />
              <br />
              Para o cadastro da conta digital são solicitados: foto da carteira
              de identidade RG ou carteira de habilitação CNH, uma foto sua,
              selfie, segurando o documento de identificação que escolheu,
              informações de endereço como, CEP, estado, cidade, bairro, rua,
              número, complemento; nome da mãe e renda mensal.
              <br />
              <br />
              Você pode adicionar cartões de outros bancos no Farmapay para
              facilitar suas formas de pagamento e transferências no dia a dia,
              inserindo as seguintes informações do seu cartão no app: número,
              nome que aparece como identificação, mês/ano de validade, código
              de segurança e apelido para o cartão, que é opcional, caso não
              digite um apelido será escolhido o nome da bandeira de seu cartão.
              <br />
              <br />
              O cartão cadastrado pelo usuário da carteira Farmapay passará por
              um processo de verificação antes de sua utilização. No processo de
              verificação é enviado para você uma cobrança, o cartão só será
              validado se no campo indicado no app o usuário digitar o valor
              correto da cobrança, caso contrário o cartão não será validado.
              Nas duas situações de cartão validado ou não o valor da cobrança
              será reembolsado para o usuário.
              <br />
              <br />
              Se você ficar com dúvidas nas etapas do processo de verificação ou
              cadastro das informações pessoais entre em contato através do
              e-mail:. Para os usuários que tiverem problema no cadastro de
              cartão de crédito, também existe um link dentro do app que dá
              acesso a um formulário para relatar as possíveis dificuldades.
              <br />
              <br />O usuário pode permitir ou não que o Farmapay tenha acesso a
              sua lista de contatos do celular para sincronizar a sua lista da
              carteira e fazer transferências. Essa permissão pode ser alterada
              no app a qualquer momento em configurações.
            </p>
          </section>
          <section className="w-full flex flex-col text-sm text-quaternary">
            <h2 className="text-2xl text-secondary mobile:mb-8">
              Utilização dos dados pessoais dos usuários
            </h2>
            <p>
              O Farmapay armazena e utiliza seus dados pessoais para os casos
              descritos no item 3.1 e realiza o compartilhamento dessas
              informações com empresas parceiras para oferecer a você todas as
              ferramentas e funcionalidades do app.
              <br />
              <br />
              Você pode alterar as configurações de compartilhamento de
              informações sempre que achar necessário, mas algumas
              funcionalidades só estarão disponíveis através da aceitação do
              usuário para o compartilhamento.
              <br />
              <br />
              O Farmapay se compromete a realizar todos os procedimentos de
              segurança para armazenamento e compartilhamento dos dados pessoais
              de seus usuários, além de procedimentos de KYC (Know Your
              Customer) – Conheça seu Cliente e procedimentos relacionados a
              Prevenção à Fraude e Lavagem de Dinheiro.
              <br />
              <br />O armazenamento dos dados pessoais dos usuários também é
              importante na resolução de reclamações, dúvidas e solicitações.
            </p>
          </section>
          <section className="w-full flex flex-col text-sm text-quaternary">
            <h2 className="text-2xl text-secondary mobile:mb-8">
              Compartilhamento de dados pessoais
            </h2>
            <p>
              Todos os parceiros possuem certificações, processos de verificação
              de segurança e estão de acordo com os tópicos estabelecidos aqui
              na Política de Privacidade, Política de Contratação de Terceiros e
              demais documentos do Farmapay.
              <br />
              <br />
              Os usuários estão de acordo com o compartilhamento de seus dados
              pessoais entre as empresas parceiras do Farmapay para uso exclusivo
              dentro das funcionalidades do app.
              <br />
              <br />
              Se o usuário decidir excluir a sua conta no Farmapay seus dados
              ficarão armazenados para comprimento de obrigações legais.
            </p>
          </section>
          <section className="w-full flex flex-col text-sm text-quaternary">
            <h2 className="text-2xl text-secondary mobile:mb-8">
              Direitos do usuário
            </h2>
            <p>
              Com relação as suas informações pessoais, os usuários possuem
              segundo as Leis nº 13.709/2018 e nº 13.853/2019 os seguintes
              direitos:
              <br />
              <br />
            </p>

            <ul className="list-disc list-inside text-xs text-secondary font-medium uppercase tracking-wide">
              <li>
                Confirmação de existência e forma de tratamento de seus dados
                pessoais.
              </li>
              <br />
              <li>
                Acesso dentro do app aos seus dados informados no início do
                cadastro.
              </li>
              <br />
              <li>
                Possibilidade de correção de dados incompletos, com erros ou
                desatualizados.
              </li>
              <br />
              <li>
                Solicitação de possibilidade de anonimização, bloqueio ou
                eliminação de dados pessoais excessivos.
              </li>
              <br />
              <li>
                Solicitação de eliminação dos dados pessoais tratados com o
                consentimento do titular, exceto se houver obrigação legal ou
                regulatória para o armazenamento.
              </li>
              <br />
              <li>
                Ser informado sobre a possibilidade de não fornecer
                consentimento e as consequências que essa atitude pode
                acarretar.
              </li>
              <br />
            </ul>

            <p>
              É importante que todos os seus dados estejam corretos e
              atualizados no app, verifique suas informações periodicamente e
              entre em contato com a gente para alterações.
              <br />
              <br />
              Os direitos citados neste capítulo foram adaptados de acordo com
              as funcionalidades do aplicativo. O Farmapay recomenda a todos os
              seus usuários a leitura das Leis nº 13.709/2018 e nº 13.853/2019
              que apresentam o conteúdo sobre proteção de dados pessoais no
              Brasil, elas se encontram disponíveis no site do planalto.
            </p>
          </section>
          <section className="w-full flex flex-col text-sm text-quaternary">
            <h2 className="text-2xl text-secondary mobile:mb-8">Comunicação</h2>
            <p>
              As formas de comunicação utilizadas pelo Farmapay para entrar em
              contato com os usuários do app são e-mail e mensagens SMS para
              confirmações de dados cadastrais e abertura de conta.
              <br />
              <br />
              O usuário do aplicativo Farmapay poderá a qualquer momento, optar
              por não receber e-mails informativos, materiais promocionais e de
              marketing por meio de solicitação no site. Os envios de e-mails
              relacionados ao desenvolvimento das funcionalidades contratadas e
              comunicações de alterações nas documentações do Farmapay não
              poderão ser interrompidos.
              <br />
              <br />O Farmapay não utiliza serviços de terceiros para enviar SMS
              ou e-mails em seu nome, se você receber alguma mensagem através
              das formas de contato preestabelecidas que acredita não ter sido
              enviada pelo Farmapay, você não deverá responder e entrar em
              contato imediatamente através dos canais de comunicação para
              verificação de veracidade das informações
            </p>
          </section>

          <section className="w-full flex flex-col text-sm text-quaternary">
            <h2 className="text-2xl text-secondary mobile:mb-8">
              Segurança de Dados
            </h2>
            <p>
              O Farmapay segue todos os procedimentos de segurança descritos em
              documentos legislativos brasileiros, todos os dados dos usuários
              são criptografados e armazenados em servidores seguros.
              <br />
              <br />
              As empresas que atuam em conjunto com o Farmapay também seguem as
              normas e possuem a certificação PCI DSS, necessária para toda
              instituição que processa, transmite ou armazena dados de cartão de
              crédito.
            </p>
          </section>

          <section className="w-full flex flex-col text-sm text-quaternary">
            <h2 className="text-2xl text-secondary mobile:mb-8">Dúvidas</h2>
            <p>
              Se você tiver qualquer dúvida sobre a Política de Privacidade e
              como ocorre o tratamento de seus dados pessoais, entre em contato
              com o Farmapay pela Central de Ajuda, através do site
              https://farmapay.com.br/ ou envie um e-mail.
            </p>
          </section>

          <section className="w-full flex flex-col text-sm text-quaternary">
            <h2 className="text-2xl text-secondary mobile:mb-8">
              Atualizações
            </h2>
            <p>
              O documento de Política de Privacidade pode passar por alterações
              devido exigências legislativas ou adequações no texto de acordo
              com novas funcionalidades do Farmapay. Cada versão com novas
              alterações será identificada de acordo com a sua versão e data de
              publicação que aparece no início e final do texto. A versão
              disponível no site será a vigente para todas as consultas.
              <br />
              <br />É responsabilidade do usuário verificar periodicamente no
              site as novas adequações da Política de Privacidade Farmapay.
            </p>
          </section>

          <section className="w-full flex flex-col text-sm text-quaternary">
            <h2 className="text-2xl text-secondary mobile:mb-8">Cookies</h2>
            <p>
              Através de suas plataformas digitais o Farmapay poderá fazer o uso
              de Cookies, o usuário deverá configurar através de seu navegador,
              caso deseje bloqueá-los. Quando isso ocorrer algumas
              funcionalidades do site poderão ser limitadas, o usuário receberá
              a pergunta se autoriza ou não a utilização de Cookies quando
              acessa o site do Farmapay.
              <br />
              <br />
              Os Cookies no site do Farmapay podem ser utilizados para
              acompanhamento de promoções, medição de padrões de navegação,
              atividades de segurança e no preenchimento de formulários.
            </p>
          </section>

          <section className="w-full flex flex-col text-sm text-quaternary">
            <h2 className="text-2xl text-secondary mobile:mb-8">
              Canal de denúncias Farmapay
            </h2>
            <p>
              O Farmapay possui um canal de denúncias para todos os seus
              colaboradores, fornecedores, clientes e toda população que possua
              algum relato de violação ao Código de Ética e Conduta e Política
              de Privacidade.
              <br />
              <br />
              Este canal é um dos meios de comunicação para relatar atitudes
              contrárias aos conceitos estabelecidos nas normas e manuais do
              Farmapay, todas informações contidas nas mensagens são
              confidenciais e se desejado pelo denunciante, anônimas.
              <br />
              <br />
              Todas as denúncias são recebidas através de uma empresa externa
              que realiza um estudo inicial e transferidas para a equipe de
              Compliance e Gestão de Risco do Farmapay para análise e
              investigação, os relatos recebem um número de protocolo para o
              acompanhamento das etapas de verificação da denúncia.
              <br />
              <br />
              Toda a equipe do Farmapay reforça que as denúncias confidenciais e
              seu conteúdo é sigilo.
              <br />
              <br />
              Através do seu relato buscamos a melhoria contínua de nossos
              produtos e serviços.
            </p>
          </section>

          <section className="w-full flex flex-col text-sm text-quaternary">
            <h2 className="text-2xl text-secondary mobile:mb-8">
              Aceitação Total dos Termos
            </h2>
            <p>
              O usuário declara ter a capacidade legal, dando o seu
              consentimento expresso nos termos constantes desta Declaração,
              aceitando todas as situações e procedimentos apresentados neste
              documento.
            </p>
          </section>

          <section className="w-full flex flex-col text-sm text-quaternary">
            <h2 className="text-2xl text-secondary mobile:mb-8">
              Histórico de Revisões
            </h2>
            <p>
              Todas as alterações da Política de Privacidade do Farmapay devem
              ser apresentadas abaixo com as informações de versão, data de
              revisão e histórico de alterações.
            </p>

            <ul className="list-disc list-inside text-xs text-secondary font-medium uppercase tracking-wide">
              <br />
              <li>Versão: 1.0</li>
              <br />
              <li>Data de Revisão: 28/02/2021</li>
              <br />
              <li>Histórico: Elaboração do Documento</li>
              <br />
            </ul>
          </section>
        </div>
      </main>
      <Footer />
    </div>
  );
};
