import React, { createContext, useState } from 'react'

interface HomeContextInterface {
  section: 'YOU' | 'BUSINESS'
  setSection: React.Dispatch<React.SetStateAction<'YOU' | 'BUSINESS'>>
}

interface HomeProviderProps {
  children: React.ReactNode
}

export const HomeContext = createContext({} as HomeContextInterface)

export const HomeProvider = (props: HomeProviderProps) => {
  const [section, setSection] = useState<'YOU' | 'BUSINESS'>('YOU')

  return (
    <HomeContext.Provider value={{ section, setSection }}>
      {props.children}
    </HomeContext.Provider>
  )
}
