import React, { SVGProps } from 'react'

export const FacebookIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} {...props}>
    <path data-name="Ret\xE2ngulo 4990" fill="none" d="M0 0h32v32H0z" />
    <path
      data-name="Uni\xE3o 3"
      d="M8.007 28.001A4.016 4.016 0 0 1 4 23.993v-16a4.007 4.007 0 0 1 4.007-3.992h16A4 4 0 0 1 28 7.99v16a4.013 4.013 0 0 1-3.993 4.011Zm16-2.678a1.331 1.331 0 0 0 1.331-1.333v-16a1.341 1.341 0 0 0-1.331-1.328h-16A1.344 1.344 0 0 0 6.662 7.99v16a1.334 1.334 0 0 0 1.345 1.333h6.353v-7.611H12v-2.745h2.36v-2.016a3.307 3.307 0 0 1 3.527-3.627c.714 0 1.413.041 2.113.109v2.459h-1.441c-1.139 0-1.358.521-1.358 1.331v1.744h2.7l-.343 2.745H17.2v7.614Z"
      fill="#7fcfd9"
    />
  </svg>
)
