import React from 'react'
import { HiArrowNarrowRight } from 'react-icons/hi'
import { Link } from 'react-router-dom'
import farmapayCards from 'assets/images/farmapay-cards.png'

export const FarmapayCards = () => (
  <article id="cards" className="w-full bg-tertiary">
    <div className="tablet:container mx-auto flex mobile:flex-col tablet:flex-row tablet:gap-x-4 mobile:px-6 tablet:px-0 mobile:my-14 tablet:my-20 desktop:my-24 relative">
      <img
        src={farmapayCards}
        alt="Cartões Farmapay"
        className="mobile:mx-auto tablet:mx-0 tablet:flex-1 tablet:order-2 tablet:max-w-xs desktop:max-w-sm"
      />

      <div className="flex flex-col tablet:flex-1 tablet:order-1 tablet:gap-y-6 tablet:justify-center">
        <h3 className="mobile:absolute tablet:static mobile:top-8 tablet:top-0 font-medium mobile:text-lg tablet:text-3xl desktop:text-4xl text-primary">
          Cartão Farmapay
          <br />
          <span className="text-secondary">
            Sem taxas, sem faturas
            <br />
            Só benefícios
          </span>
        </h3>
        <Link
          to="/"
          className="flex items-center mobile:justify-center tablet:justify-start gap-x-2 text-primary mobile:text-sm tablet:text-lg font-medium w-full"
        >
          Peça o seu <HiArrowNarrowRight className="w-5 h-5" />
        </Link>
      </div>
    </div>
  </article>
)
