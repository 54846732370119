import React, { useEffect, useState } from 'react'

import { Logo } from 'components/svgs/Logo'
import { ShowMenuButton } from './ShowMenuButton'
import { Link } from 'react-router-dom'
import { HiOutlineLogout, HiArrowNarrowRight } from 'react-icons/hi'
import PlayStoreImg from 'assets/images/play-store.png'
import AppStoreImg from 'assets/images/app-store.png'
import { LinkButton } from 'components/LinkButton'
import { useHome } from 'hooks/useHome'

import { useModal } from 'hooks/useModal'

const MobileNav = ({ closeMenu, section, highlightSection }: any) => {
  return (
    <nav
      className={`
  mobile:left-0
  mobile:pt-24
  mobile:px-5.5
  mobile:pb-6
  mobile:overflow-x-hidden
  mobile:overflow-y-scroll
  h-full
  flex
  mobile:flex-col
  w-full
  bg-tertiary
  desktop:hidden
  `}
    >
      <ul className="flex mobile:flex-col desktop:flex-row gap-x-6 gap-y-4 font-medium text-lg">
        <li className="flex mobile:flex-row desktop:flex-col items-center relative">
          <Link to="/#start" className="">
            Início
          </Link>
          <div
            className={`mobile:hidden desktop:flex bg-primary h-1 w-4 rounded-md absolute top-7 transition-all duration-300 ${
              highlightSection === 'START' ? 'opacity-1' : 'opacity-0'
            }`}
          />
        </li>
        <li className="flex mobile:flex-row desktop:flex-col items-center relative">
          <Link to="/#for-you" onClick={closeMenu} className="">
            Para você
          </Link>
          <div
            className={`mobile:hidden desktop:flex bg-primary h-1 w-4 rounded-md absolute top-7 transition-all duration-300 ${
              highlightSection === 'SECTIONS' && section === 'YOU'
                ? 'opacity-1'
                : 'opacity-0'
            }`}
          />
        </li>
        <li className="flex mobile:flex-row desktop:flex-col items-center relative">
          <Link to="/#for-business" onClick={closeMenu} className="">
            Para seu negócio
          </Link>
          <div
            className={`mobile:hidden desktop:flex bg-primary h-1 w-4 rounded-md absolute top-7 transition-all duration-300 ${
              highlightSection === 'SECTIONS' && section === 'BUSINESS'
                ? 'opacity-1'
                : 'opacity-0'
            }`}
          />
        </li>
        <li className="desktop:hidden">
          <Link to="/" className="">
            Blog
          </Link>
        </li>
        <li className="desktop:hidden">
          <Link to="/" className="flex items-center gap-x-2">
            Login{' '}
            <HiOutlineLogout
              className="desktop:hidden"
              width={24}
              height={24}
            />
          </Link>
        </li>
      </ul>

      <article className="w-full flex items-center justify-center gap-x-3 mt-14 mb-8 desktop:hidden">
        <img src={PlayStoreImg} alt="Play Store" className="" />
        <img src={AppStoreImg} alt="App Store" className="" />
      </article>

      <p className="font-medium text-lg text-center w-full text-quaternary desktop:hidden">
        <span className="font-bold text-secondary">Baixe e aproveite!</span>
        <br />
        <br />
        <br />
        Tem um negócio? Cadastre-se e tenha vantagens em ser Farmapay!
      </p>

      <LinkButton
        to="#name"
        className="mt-6 desktop:hidden p-4.5 gap-x-2 text-base"
      >
        Cadastre-se no Farmapay <HiArrowNarrowRight className="w-5 h-5" />
      </LinkButton>
    </nav>
  )
}

export const Header = () => {
  const modal = useModal()
  const [showMenu, setShowMenu] = useState(false)
  const [highlightSection, setHighlightSection] = useState<
    'START' | 'SECTIONS'
  >('START')
  const { section } = useHome()

  const closeMenu = () => {
    setShowMenu(false)
    modal.close()
  }

  const toggleMenu = () => {
    const nextState = !showMenu

    if (nextState) {
      modal.open(
        <MobileNav
          showMenu={showMenu}
          closeMenu={closeMenu}
          section={section}
          highlightSection={highlightSection}
        />,
        {
          initial: { opacity: 0, top: '-100vh' },
          animate: { opacity: 1, top: '0' },
          exit: { opacity: 0, top: '-100vh' },
          className: 'bg-white'
        }
      )
    } else {
      modal.close()
    }

    setShowMenu(nextState)
  }

  useEffect(() => {
    const html = document.querySelector('html')
    const sectionsContainer = document.querySelector('#sections-container')
    const offsetTop = sectionsContainer?.getBoundingClientRect().top

    document.body!.onscroll = () => {
      if (html?.scrollTop && offsetTop) {
        if (html?.scrollTop >= offsetTop) {
          setHighlightSection('SECTIONS')
        } else {
          setHighlightSection('START')
        }
      }
    }

    return () => {
      document.body!.onscroll = null
    }
  }, [])

  return (
    <header className="fixed bg-tertiary w-full top-0 mobile:z-999 desktop:z-50">
      <article className="flex items-center justify-between mobile:pl-5.5 desktop:px-0 desktop:py-8 desktop:flex-row desktop:container mx-auto relative">
        <Logo className="z-50 bg-tertiary" />

        <nav
          className={`
        mobile:hidden
        desktop:flex
        desktop:h-auto
        desktop:pt-0
        desktop:pb-0
        desktop:px-0
        desktop:relative
        desktop:opacity-100
        flex-1
        mobile:flex-col
        desktop:flex-row
        transition-all
        duration-700
        delay-300
        w-full
        desktop:overflow-visible
        bg-tertiary
        desktop:top-0
        desktop:mt-0
        desktop:ml-9
        `}
        >
          <ul className="flex mobile:flex-col desktop:flex-row gap-x-6 gap-y-4 font-medium text-lg">
            <li className="flex mobile:flex-row desktop:flex-col items-center relative">
              <Link to="/#start" className="">
                Início
              </Link>
              <div
                className={`mobile:hidden desktop:flex bg-primary h-1 w-4 rounded-md absolute top-7 transition-all duration-300 ${
                  highlightSection === 'START' ? 'opacity-1' : 'opacity-0'
                }`}
              />
            </li>
            <li className="flex mobile:flex-row desktop:flex-col items-center relative">
              <Link to="/#for-you" className="">
                Para você
              </Link>
              <div
                className={`mobile:hidden desktop:flex bg-primary h-1 w-4 rounded-md absolute top-7 transition-all duration-300 ${
                  highlightSection === 'SECTIONS' && section === 'YOU'
                    ? 'opacity-1'
                    : 'opacity-0'
                }`}
              />
            </li>
            <li className="flex mobile:flex-row desktop:flex-col items-center relative">
              <Link to="/#for-business" className="">
                Para seu negócio
              </Link>
              <div
                className={`mobile:hidden desktop:flex bg-primary h-1 w-4 rounded-md absolute top-7 transition-all duration-300 ${
                  highlightSection === 'SECTIONS' && section === 'BUSINESS'
                    ? 'opacity-1'
                    : 'opacity-0'
                }`}
              />
            </li>
            <li className="desktop:hidden">
              <Link to="/" className="">
                Blog
              </Link>
            </li>
            <li className="desktop:hidden">
              <Link to="/" className="flex items-center gap-x-2">
                Login{' '}
                <HiOutlineLogout
                  className="desktop:hidden"
                  width={24}
                  height={24}
                />
              </Link>
            </li>
          </ul>

          <article className="w-full flex items-center justify-center gap-x-3 mt-14 mb-8 desktop:hidden">
            <img src={PlayStoreImg} alt="Play Store" className="" />
            <img src={AppStoreImg} alt="App Store" className="" />
          </article>

          <p className="font-medium text-lg text-center w-full text-quaternary desktop:hidden">
            <span className="font-bold text-secondary">Baixe e aproveite!</span>
            <br />
            <br />
            <br />
            Tem um negócio? Cadastre-se e tenha vantagens em ser Farma!
          </p>

          <LinkButton
            to="/#name"
            className="mt-6 desktop:hidden p-4.5 gap-x-2 text-base"
          >
            Cadastre-se no Farma <HiArrowNarrowRight className="w-5 h-5" />
          </LinkButton>
        </nav>
        <Link
          to="/"
          className="mobile:hidden desktop:flex items-center justify-center gap-x-2 bg-opacity-0 text-secondary text-lg font-medium"
        >
          Login <HiOutlineLogout className="text-primary w-6 h-6" />
        </Link>
        <LinkButton
          to="#download"
          className="mobile:hidden desktop:flex p-4.5 text-base ml-4 gap-x-2"
        >
          Baixe o app <HiArrowNarrowRight className="w-5 h-5" />
        </LinkButton>
        <ShowMenuButton
          showMenu={showMenu}
          onClick={toggleMenu}
          className="desktop:hidden py-4.5 px-6 z-50"
        />
      </article>
    </header>
  )
}
