import React from 'react'
import cellphones from 'assets/images/cellphones.png'
import { Link } from 'react-router-dom'
import { HiArrowNarrowRight } from 'react-icons/hi'
import manWithoutMachine from 'assets/images/man-without-machine.png'

export const FarmapayTapAndGo = () => {
  return (
    <article id="tap-and-go" className="w-full bg-tertiary">
      <div className="w-full desktop:container tablet:container tablet:mx-auto mobile:pt-14">
        <h3 className="mobile:text-left tablet:text-center text-secondary font-medium mobile:text-2xl tablet:text-3xl desktop:text-4xl mobile:px-6">
          Conheça o <br /> Tap-and-GO{' '}
          <span className="text-primary">Farmapay</span>
        </h3>
        <p className="text-quaternary font-medium text-lg w-full mobile:text-left tablet:text-center mt-2 mobile:px-6">
          Aceitar pagamentos ficou mais fácil!
        </p>

        <img
          src={cellphones}
          alt="Tranfêrencia entre celulares"
          className="object-cover w-full mobile:mx-auto mobile:h-72 tablet:h-80 mobile:w-max tablet:w-full tablet:max-w-2xl max-h-80 rounded-3xl mobile:mt-6 tablet:mt-8 mobile:mb-8"
        />

        <p
          className="w-full
        tablet:max-w-xl
        tablet:text-center
        tablet:mx-auto
        mobile:px-6
        tablet:px-0
        mobile:mt-20
        tablet:mt-0
        font-medium
        mobile:text-lg
        tablet:text-xl
        text-quaternary"
        >
          Na <span className="text-primary">Farmapay</span> você não precisa de
          aparelho adicional para receber pagamentos, basta o seu celular!
        </p>

        <div className="w-full tablet:container tablet:mx-auto flex items-start mobile:gap-x-4 desktop:gap-x-16 mobile:mt-8">
          <img
            src={manWithoutMachine}
            alt="Sem maquininha"
            className="mobile:hidden tablet:block tablet:w-52 tablet:h-80 desktop:w-80 desktop:h-72 rounded-l-10 rounded-br-10 object-cover"
          />
          <div className="flex-1 flex flex-col items-start mobile:gap-y-2 tablet:gap-y-4 mobile:px-6 tablet:px-0">
            <span className="text-secondary font-bold mobile:text-lg tablet:text-3xl desktop:text-4xl">
              Sem maquininha?
            </span>

            <p className="mobile:text-sm tablet:text-lg text-quaternary">
              <span className="text-primary font-bold">Sim!</span> Com um
              dispositivo Android, você pode aceitar pagamentos seguros e sem
              contato, apenas aproximando cartões, smartphones, relógios
              inteligentes com a tecnologia NFC. Incrivelmente fácil e rápido!
            </p>

            <Link
              to="/"
              className="mobile:text-xs desktop:text-lg text-quinary tablet:underline"
            >
              Consulte os termos e condições deste serviço.
            </Link>

            <Link
              to="/"
              className="flex items-center gap-x-2 font-medium mobile:text-sm tablet:text-lg text-primary mobile:mt-4 tablet:mt-8"
            >
              Cadastre-se e conheça mais{' '}
              <HiArrowNarrowRight className="w-5 h-5 text-primary" />
            </Link>
          </div>
        </div>
      </div>
    </article>
  )
}
